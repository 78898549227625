import React from 'react';
import styled from '@emotion/styled';
import { DeleteOutline as DeleteIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import Panel from 'app/components/common/Panel';
import {
  BorderConfigDataInputForBackend,
  BorderConfigResponse,
  BorderConfigV2,
  BorderRuleOperationMode,
  StrategyTypes,
} from 'app/types/border';

export const STRATEGY_TYPES_ARR = Object.values(StrategyTypes);

export const NOT_SEARCHABLE_KEY = 'Not Searchable';

export const SEARCHABLE_KEYS_LONG = Array.from({ length: 25 }, (_, i) => `key${i + 1}`);
export const SEARCHABLE_KEYS_SHORT = Array.from({ length: 5 }, (_, i) => `key${i + 21}`);
export const AGGREGATED_KEYS = Array.from({ length: 10 }, (_, i) => `range_key${i + 1}`);

export const STRATEGY_DEFAULT_LENGTH = 20;
export const STRATEGY_PERSISTENT_DEFAULT_LENGTH = 64;

interface IDeleteButtonProps {
  title?: string;
  onClick: () => void;
}

export const DeleteButton: React.FunctionComponent<IDeleteButtonProps> = ({ onClick, title = 'Delete' }) => (
  <DeleteButtonWrapper>
    <Tooltip title={title} placement='top'>
      <IconButton aria-label='Delete' onClick={onClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  </DeleteButtonWrapper>
);

const DeleteButtonWrapper = styled.div`
  place-self: end;
`;

export const convertBorderConfigForBackend = (border: BorderConfigV2): BorderConfigDataInputForBackend => {
  return {
    name: border.name,
    target: border.data.target,
    redactions: border.data.redactions?.map(r => {
      const { operationMode, ...rule } = r;
      return {
        ...rule,
        searchable: (rule.strategies || []).reduce((prev, curr) => {
          if (curr.searchableKey && curr.searchableKey !== NOT_SEARCHABLE_KEY) {
            return { ...prev, [curr.searchableKey]: curr.path };
          }
          return prev;
        }, {}),
        strategies: rule.strategies?.map(strategyItem => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { searchableKey, ...rest } = strategyItem;
          const length = rest.strategyOptions?.length as unknown as string;
          const value = rest.strategyOptions?.value;
          return {
            ...rest,
            strategyOptions: {
              ...rest.strategyOptions,
              ...(length !== undefined ? { length: parseInt(length, 10) } : {}),
              ...(value !== undefined ? { value: value?.toString() } : {}),
              storeField: operationMode === BorderRuleOperationMode.processingAndStorage,
            },
          };
        }),
      };
    }),
    unredactions: border.data.unredactions?.map(v => ({
      ...v,
      collections: v.collections?.map(collection => {
        const entityErrorCorrectionFieldPath = collection.entityErrorCorrectionFieldPath;
        delete collection.isErrorCorrectionFieldIdx;
        return {
          ...collection,
          strategies: collection.strategies?.map(strategy => {
            if (strategy.path === entityErrorCorrectionFieldPath) {
              return { ...strategy, isErrorCorrectionField: true };
            }
            delete strategy.isErrorCorrectionField;
            return strategy;
          }),
        };
      }),
    })),
  };
};

export const convertBorderConfigForForm = (borderResponse: BorderConfigResponse): BorderConfigV2 => {
  return {
    ...borderResponse,
    data: {
      ...borderResponse?.data,
      redactions: (borderResponse?.data?.redactions || []).map(a => ({
        ...a,
        strategies: a.strategies.map(strategy => {
          const searchableKey = Object.entries(a.searchable)
            .filter(v => v[1] === strategy.path)
            .map(v => v[0])?.[0];
          return {
            ...strategy,
            searchableKey: searchableKey || NOT_SEARCHABLE_KEY,
          };
        }),
        operationMode: a.strategies.every(v => v.strategyOptions?.storeField)
          ? BorderRuleOperationMode.processingAndStorage
          : BorderRuleOperationMode.processing,
      })),
      unredactions: (borderResponse?.data?.unredactions || []).map(unredaction => ({
        ...unredaction,
        collections: unredaction.collections?.map(collection => {
          return {
            ...collection,
            isErrorCorrectionFieldIdx: collection.strategies?.findIndex(stg => stg.isErrorCorrectionField)?.toString(),
          };
        }),
      })),
    },
  };
};

export const Rule = styled.div`
  margin: 16px 0;
`;

export const Collection = styled(Panel)`
  margin-bottom: 24px;
`;

export const RuleType = styled.div`
  display: inline-block;
  margin-right: 16px;
  font-weight: 600;
`;
export const RedactionRuleHead = styled.div`
  display: grid;
  grid-template-columns: auto minmax(90px, 0.1fr) 1fr minmax(180px, 0.3fr) auto;
  column-gap: 8px;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  > * {
    &:first-child {
      margin-top: 4px;
    }
    &:last-child {
      margin-top: 4px;
      margin-left: auto;
    }
  }
`;

export const UnredactionRuleHead = styled.div`
  display: grid;
  grid-template-columns: auto 0.1fr 1fr auto;
  column-gap: 8px;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  > * {
    &:first-child {
      margin-top: 4px;
    }
    &:last-child {
      margin-top: 4px;
      margin-left: auto;
    }
  }
`;
