import { CountryResponseInterface } from 'app/types/country';
import { countries } from 'app/utils/countries';

class Country {
  code: string;
  name: string;
  region?: 'AMER' | 'EMEA' | 'APAC';

  constructor(data: CountryResponseInterface | string) {
    if (typeof data === 'string' && data.length === 2) {
      const countryData = countries.filter(v => v.country_code.toLowerCase() === data.toLowerCase())?.[0];
      if (countryData) {
        this.code = countryData.country_code;
        this.name = countryData.name;
      } else {
        this.code = data;
        this.name = data;
      }
    } else if (typeof data !== 'string' && isCountryResponse(data)) {
      this.code = data.id;
      this.name = data.name;
      this.region = data.region;
    } else {
      throw new Error('Cannot construct Country object');
    }
  }
}

export default Country;

const isCountryResponse = (data: unknown): data is CountryResponseInterface => {
  const d = data as CountryResponseInterface;
  if (d?.id !== undefined && d?.name !== undefined && d?.region !== undefined) {
    return true;
  } else {
    throw new Error('Data is not CountryResponseInterface');
  }
};
