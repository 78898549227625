import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import SnackbarProvider from 'app/utils/context/SnackbarProvider';

const Providers: React.FC = ({ children }) => {
  return (
    <SnackbarProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
    </SnackbarProvider>
  );
};

export default Providers;
