import PortalConfig from 'app/api/models/PortalConfig';

const BaseColors = {
  primary: { main: '#4F00F3', dark: '#4000C3' },
  secondary: { main: '#FF0057', dark: '#c70044' },
  alternative: { main: '#a8bac3', dark: '#253b49', light: '#e3ebef' },

  text: { primary: '#1C3340', secondary: '#1c3340a8' },

  gray: { main: '#e0e0e0', light: '#fafaf8', border: '#38698033' },

  white: '#ffffff',
  black: '#000000',

  status: {
    success: '#93D813',
    info: '#009DFF',
    warning: {
      color: '#E19234',
      background: '#F4C413',
    },
    error: '#FF631C',
  },
};

const CustomColors = {
  ...BaseColors,
  primary: { main: '#FF6B01', dark: '#EB7429' },
  secondary: { main: '#FF6B01', dark: '#EB7429' },
};

export const colors = PortalConfig.isAliCloud() ? CustomColors : BaseColors;
