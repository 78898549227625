import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { alpha, Chip, Divider, ListItemText } from '@mui/material';
import Link from 'next/link';

import api from 'app/api/client';
import { CurrentUser } from 'app/api/models/CurrentUser';
import PortalConfig from 'app/api/models/PortalConfig';
import Logo from 'app/components/common/Logo';
import DropdownMenu from 'app/components/layout/DropdownMenuItem';
import SubmenuItem from 'app/components/layout/SubmenuItem';
import { colors } from 'app/theme-colors';
import { BillingPlan } from 'app/types/billing';
import { EXTERNAL_LINKS, Route, Routes } from 'app/utils/constants';

interface IProps {
  user: CurrentUser;
}

const MainMenu: React.FC<IProps> = ({ user }) => {
  const [plan, setPlan] = useState<BillingPlan>(BillingPlan.Trial);

  const { email, defaultOrgName, defaultOrg, isAccessGranted } = user;

  const { t } = useTranslation('MainMenu');

  useEffect(() => {
    if (defaultOrg) setPlan(defaultOrg?.plan || BillingPlan.Trial);
  }, [defaultOrg]);

  const onLogoutCurrent = () => api?.logout();

  const renderMainMenuItem = (label: string, href: Route, allowed = false) => {
    return (
      <MainMenuItemWrapper>
        {allowed ? (
          <Link href={href} passHref>
            <MainMenuItemLink>{label}</MainMenuItemLink>
          </Link>
        ) : (
          <MainMenuItemDisabled>{label}</MainMenuItemDisabled>
        )}
      </MainMenuItemWrapper>
    );
  };

  const getMenuConfig = () => {
    return {
      inAppExtensions: [
        {
          href: Routes.paymentVault.list(),
          disabled: !isAccessGranted?.(Routes.paymentVault.list().pathname),
          child: t('paymentVault'),
        },
        {
          href: Routes.emailGateway.list(),
          disabled: !isAccessGranted?.(Routes.emailGateway.list().pathname),
          child: t('emailGateway'),
        },
        { href: Routes.htmlGateway.list(), disabled: true, child: t('htmlGateway') },
        { href: Routes.webFormGateway.list(), disabled: true, child: t('webFormGateway') },
      ],
    };
  };

  const filterAclAllowed = (v: { href: Route }) => PortalConfig.acl[v.href.pathname] !== false;

  const renderSupportLinks = () => {
    const sdkSupportLinks = {
      pythonSdk: EXTERNAL_LINKS.DOCS.PYTHON_SDK,
      nodeJsSdk: EXTERNAL_LINKS.DOCS.NODE_JS_SDK,
      javaSdk: EXTERNAL_LINKS.DOCS.JAVA_SDK,
      cSharpSdk: EXTERNAL_LINKS.DOCS.C_SHARP_SDK,
    };

    const supportLinks = {
      portal: EXTERNAL_LINKS.DOCS.LANDING,
      salesforce: EXTERNAL_LINKS.DOCS.SALESFORCE,
      restApi: EXTERNAL_LINKS.DOCS.REST_API,
      border: EXTERNAL_LINKS.DOCS.BORDER,
      ...(PortalConfig.isDefault() ? sdkSupportLinks : {}),
    };

    if (PortalConfig.isDefault() || PortalConfig.isDCC()) {
      return (
        <>
          <Divider />
          {Object.keys(supportLinks).map(k => (
            <SubmenuItem key={k} isRemote={true} href={supportLinks[k as keyof typeof supportLinks]}>
              {t(k)}
            </SubmenuItem>
          ))}
        </>
      );
    }
    return null;
  };

  const renderContactSupport = () => {
    if (PortalConfig.isDCC()) return null;
    if (PortalConfig.supportTicket !== '') {
      return (
        <>
          <Divider />
          <SubmenuItem href={PortalConfig.supportTicket} isRemote={true}>
            {t('contactSupport')}
          </SubmenuItem>
        </>
      );
    }
    return (
      <>
        <Divider />
        <SubmenuItem href={`mailto:${PortalConfig.supportEmail || ''}`} isRemote={true}>
          {t('contactSupport')}
        </SubmenuItem>
      </>
    );
  };

  return (
    <Wrapper>
      <StyledLogo />

      <CompanyMenuItem label={defaultOrgName} title={defaultOrgName}>
        {isAccessGranted?.(Routes.plans.list().pathname) && (
          <SubmenuItem disabled={!isAccessGranted?.(Routes.plans.list().pathname)} href={Routes.plans.list()}>
            <ListItemText>{t('subscription')}</ListItemText>&nbsp;
            <StyledChip size='small' color='primary' label={plan} />
          </SubmenuItem>
        )}
        <SubmenuItem disabled={!isAccessGranted?.(Routes.member.list().pathname)} href={Routes.member.list()}>
          {t('members')}
        </SubmenuItem>
        <SubmenuItem disabled={!isAccessGranted?.(Routes.company.list().pathname)} href={Routes.company.list()}>
          {t('companySettings')}
        </SubmenuItem>
      </CompanyMenuItem>

      {PortalConfig.acl[Routes.dashboard.list().pathname] &&
        renderMainMenuItem(
          t('dashboard'),
          Routes.dashboard.list(),
          isAccessGranted?.(Routes.dashboard.list().pathname),
        )}

      {PortalConfig.acl[Routes.environment.list().pathname] &&
        renderMainMenuItem(
          t('environments'),
          Routes.environment.list(),
          isAccessGranted?.(Routes.environment.get().pathname),
        )}

      {Object.entries(getMenuConfig()).map(([key, value]) =>
        value.filter(filterAclAllowed).length > 0 ? (
          <DropdownMenuItem key={key} label={t(key)}>
            {value.filter(filterAclAllowed).map((v, idx) => (
              <SubmenuItem key={idx} href={v.href} disabled={v.disabled}>
                {v.child}
              </SubmenuItem>
            ))}
          </DropdownMenuItem>
        ) : null,
      )}

      <DropdownMenuItem label={t('support')}>
        {PortalConfig.acl[Routes.gettingStarted.list().pathname] && (
          <>
            <SubmenuItem
              href={Routes.gettingStarted.list()}
              disabled={!isAccessGranted?.(Routes.gettingStarted.list().pathname)}
            >
              {t('gettingStarted')}
            </SubmenuItem>
            <Divider />
          </>
        )}
        <SubmenuItem isRemote={true} href={PortalConfig.urlToDocs}>
          {t('documentation')}
        </SubmenuItem>
        {renderSupportLinks()}
        {renderContactSupport()}
      </DropdownMenuItem>

      <ProfileMenuItem label={email} title={email} placement='bottom-end'>
        {PortalConfig.acl[Routes.profile.list().pathname] && (
          <SubmenuItem href={Routes.profile.list()} disabled={!isAccessGranted?.(Routes.profile.list().pathname)}>
            <ListItemText>{t('profile')}</ListItemText>
          </SubmenuItem>
        )}

        <SubmenuItem onClick={onLogoutCurrent}>{t('logout')}</SubmenuItem>
      </ProfileMenuItem>
    </Wrapper>
  );
};

const FlexRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const MenuItemBaseCSS = css`
  display: flex;
  height: 90px;
  cursor: pointer;
  min-height: 90px;
  align-items: center;
  position: relative;

  &:before {
    transition: background-color 500ms;
    content: '';
    width: 100%;
    height: 4px;
    background-color: transparent;
    position: absolute;
    top: -4px;
    left: 0;
  }

  & > span,
  & > a {
    transition: color 500ms;
  }

  &:hover:before {
    background-color: ${alpha(colors.secondary.main, 0.2)};
  }

  &:hover a {
    color: ${colors.secondary.main} !important;
  }

  &:hover > span {
    color: ${colors.secondary.main} !important;
  }
`;

const DropdownMenuItem = styled(DropdownMenu)`
  ${MenuItemBaseCSS}
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  height: 90px;
  min-height: 90px;
  min-width: 100%;
  align-items: center;
  gap: 24px;
`;

const MainMenuItemWrapper = styled(FlexRow)`
  ${MenuItemBaseCSS}
  line-height: 91px;
`;

const ProfileMenuItem = styled(DropdownMenuItem)`
  margin-left: auto;
`;

const CompanyMenuItem = styled(DropdownMenuItem)`
  margin-right: 16px;
`;

const MainMenuItemLink = styled.a`
  text-decoration: none;
  height: 100%;
  display: inline-block;
  color: ${colors.text.primary};
`;

const MainMenuItemDisabled = styled.span`
  text-decoration: none;
  display: inline-block;
  color: ${alpha(colors.text.primary, 0.5)};
`;

const StyledLogo = styled(Logo)`
  height: 30px;
  width: 30px;
  max-height: 30px;
  margin-right: -8px;
`;

const StyledChip = styled(Chip)`
  margin-left: 8px;
`;

export default MainMenu;
