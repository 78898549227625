import React from 'react';
import styled from '@emotion/styled';
import { Paper as MuiPaper } from '@mui/material';

import { colors } from 'app/theme-colors';

const Panel: React.FC = ({ ...props }) => {
  return <PaperStyled variant='outlined' {...props} />;
};

const PaperStyled = styled(MuiPaper)`
  padding: ${({ theme }) => theme.spacing(3)};
  border-color: ${colors.gray.border};
`;

export default Panel;
