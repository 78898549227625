import { formatISO } from 'date-fns';
import { GetServerSidePropsContext } from 'next';
import getConfig from 'next/config';

import EncryptionKey, { EncryptionKeyRequestBody } from 'app/api/models/EncryptionKey';
import Service from 'app/api/models/Service';
import { FormInputs as CreateEncryptionKeyFormInputs } from 'app/forms/CreateEncryptionKeyForm';
import { FormInputs as RotateEncryptionKeyFormInputs } from 'app/forms/UpdateEncryptionKeyForm';
import { ICheckCodeResponse } from 'app/types/auth';
import { BorderConfigDataInputForBackend, BorderConfigResponse, BorderConfigV2 } from 'app/types/border';
import { CompanyBaseInterface, CompanyUpdateResponse } from 'app/types/company';
import { CountriesResponseInterface, CountryResponseInterface } from 'app/types/country';
import {
  ICountryDataV2,
  IUsageDataByEnvV2,
  UsageTotalsInterface,
  UsageTotalsResponseInterface,
} from 'app/types/dashboard';
import {
  CreateEmailGatewayInterface,
  EmailGatewayInterface,
  UpdateEmailGatewayInterface,
} from 'app/types/email-gateways';
import { EncryptionKeyResponse } from 'app/types/encryption';
import {
  CreateEnvironmentRequest,
  CreateEnvironmentResponse,
  EnvironmentResponseData,
  SdkResponse,
} from 'app/types/environments';
import { InfraEndpointResponse } from 'app/types/infraJson';
import {
  InviteMemberRequest,
  InviteMemberResponse,
  InviteResponseInterface,
  MemberResponseInterface,
} from 'app/types/members';
import {
  GetServerSidePropsResult,
  isNotFoundResultSsr,
  isPropsResultSsr,
  isRedirectResultSsr,
  NextGetConfig,
  RouterQueryItem,
  ServerSidePropsContextType,
} from 'app/types/nextjs';
import {
  CreatePaymentVaultBodyInterface,
  CreatePaymentVaultInterface,
  PaymentProviderInterface,
  PaymentVaultInterface,
} from 'app/types/payment-vault';
import { ServiceResponse, ServiceTypeFilter, ServiceTypeResponse } from 'app/types/services';
import { CurrentUserResponse } from 'app/types/user';
import { convertBorderConfigForBackend, convertBorderConfigForForm } from 'app/utils/border';
import { CONFIRMATION_HEADER, COUNTRY_HEADER } from 'app/utils/constants';
import { HttpMethod } from 'app/utils/constants/http';
import { COOKIES_TYPES, cookiesService } from 'app/utils/cookies';
import { sortCountriesArray } from 'app/utils/countries';
import { prepareNewEncryptionKeyPayload } from 'app/utils/encryptionKeys';
import { isProduction } from 'app/utils/environmentVariables';
import { ServerErrorInterface } from 'app/utils/errors';
import { isBrowser } from 'app/utils/misc';
import { parseQueryParam } from 'app/utils/next/router';

const {
  publicRuntimeConfig: { IC_WD_SERVER_API },
} = getConfig() as NextGetConfig;

class ApiClient {
  private headers: Record<string, string> = {};
  private countryCode?: string;

  constructor() {
    this.headers = {
      'Content-Type': 'application/json',
    };
  }

  setCountryCodeHeader(countryCode?: string) {
    if (!countryCode) return;
    this.countryCode = countryCode;
  }

  private serialize = (obj: Record<string, unknown>, prefix?: string): string => {
    const str = [];
    let p;
    for (p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p] as Record<string, unknown>;
        str.push(
          v !== null && typeof v === 'object'
            ? this.serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v as string),
        );
      }
    }
    return str.join('&');
  };

  private url(path: string, query?: Record<string, unknown>): string {
    const url = new URL(`${IC_WD_SERVER_API}${path}`);
    if (query) {
      url.search = this.serialize(query);
    }
    return url.toString();
  }

  private getCsrfToken = (ctx?: ServerSidePropsContextType) => {
    if (isBrowser) {
      return cookiesService.getCookie(COOKIES_TYPES.CSRF, document.cookie);
    } else if (ctx?.req?.headers?.cookie) {
      return cookiesService.getCookie(COOKIES_TYPES.CSRF, ctx.req.headers.cookie);
    }
  };

  private async request<T>(url: string, options: RequestInit, ctx?: ServerSidePropsContextType): Promise<T> {
    const headers = {
      ...this.headers,
      ...options.headers,
      [COUNTRY_HEADER]: this.countryCode || '',
    } as { [key: string]: string };

    const csrfToken = this.getCsrfToken();
    if (csrfToken) headers['X-CSRF-TOKEN'] = csrfToken;
    if (ctx) headers['Cookie'] = ctx.req.headers.cookie as string;

    const request = new Request(url, {
      ...options,
      credentials: isProduction() ? 'same-origin' : 'include',
      headers,
    });

    const response = await fetch(request);

    const cookie = response.headers.get('set-cookie');

    if (ctx && cookie) {
      ctx.res?.setHeader('set-cookie', cookie);
    }

    if (response.status === 403) {
      throw new Error('Forbidden configuration');
    }

    if (response.ok) {
      if (response.status === 204) {
        return undefined as never;
      }
      return response
        .json()
        .then(data => data as T)
        .catch(() => response as unknown as T);
    } else if (response.status === 452) {
      if (ctx) return undefined as never;
      return new Promise(res => setTimeout(() => res(this.request(url, options)), 3000));
    } else {
      throw response;
    }
  }

  private async get<T>(
    path: string,
    query: Record<string, unknown> = {},
    options?: RequestInit,
    ctx?: ServerSidePropsContextType,
  ): Promise<T> {
    const url = this.url(path, query);
    const config = { method: HttpMethod.Get, ...options };
    return this.request<T>(url, config, ctx);
  }

  private async post<T, U>(
    path: string,
    body?: T,
    options?: RequestInit,
    ctx?: ServerSidePropsContextType,
  ): Promise<U> {
    const url = this.url(path);
    const config = { method: HttpMethod.Post, body: JSON.stringify(body), ...options };
    return this.request<U>(url, config, ctx);
  }

  private async put<T, U>(path: string, body?: T, options?: RequestInit): Promise<U> {
    const url = this.url(path);
    const config = { method: HttpMethod.Put, body: JSON.stringify(body), ...options };
    return this.request<U>(url, config);
  }

  private async patch<T, U>(path: string, body?: T, options?: RequestInit): Promise<U> {
    const url = this.url(path);
    const config = { method: HttpMethod.Patch, body: JSON.stringify(body), ...options };
    return this.request<U>(url, config);
  }

  private async delete<T>(path: string, body?: T, options?: RequestInit): Promise<T> {
    const url = this.url(path);
    const config = { method: HttpMethod.Delete, body: JSON.stringify(body), ...options };
    return this.request<T>(url, config);
  }

  public fetchAuthProviderRedirectUrl = async (providerId?: string) => {
    const query = providerId ? { identity_provider: providerId } : undefined;
    const response = await this.get<{ redirectUrl: string }>('/auth/v2/login', query);
    return response.redirectUrl;
  };

  public createNewOrg = async (name: string, countryCode: string) => {
    const data = { name, country: countryCode };
    return this.post<{ name: string; country: string }, unknown>('/company', data);
  };

  public createEnvironment = (type: string, name: string) => {
    return this.post<CreateEnvironmentRequest, CreateEnvironmentResponse>('/environments', {
      type,
      name,
    });
  };

  public updateEnvironment = async (environmentId: RouterQueryItem, type: string, name: string, dtk?: string) => {
    if (environmentId) {
      const payload = dtk ? { type, name, dtk } : { type, name };
      const id = environmentId.toString();
      return this.patch<CreateEnvironmentRequest, CreateEnvironmentResponse>(`/environments/${id}`, payload);
    }
  };

  public restoreEnvironment = async (id: string) => {
    return this.post<object, CreateEnvironmentResponse>(`/environments/${id}/restore`, {});
  };

  public deleteEnvironment = async (environmentId: RouterQueryItem) => {
    if (environmentId) {
      const id = environmentId.toString();
      return this.delete(`/environments/${id}`);
    }
  };

  public createSdk = async (envId: string, name: string) => {
    const response = await this.post<{ name: string }, { data: SdkResponse }>(`/environments/${envId}/clients`, {
      name,
    });
    return response?.data;
  };

  public updateSdk = async (envId: string, sdkId: string, name: string) => {
    return this.patch<{ name: string }, unknown>(`/environments/${envId}/clients/${sdkId}`, { name });
  };

  public deleteSdk = async (envId: string, sdkId: string) => {
    return this.delete<unknown>(`/environments/${envId}/clients/${sdkId}`);
  };

  public fetchServiceCountries = async (filter: ServiceTypeFilter) => {
    const response = await this.get<{ data: InfraEndpointResponse[] }>('/infra', {
      filter,
    });
    return response?.data;
  };

  public deleteService = async (envId: string, serviceId: string) => {
    return this.delete<unknown>(`/environments/${envId}/services/${serviceId}`);
  };

  public createService = async (
    envId: string,
    serviceTypeId: string,
    name: string,
    countryCode: string,
    oss: boolean,
  ) => {
    const data = {
      name,
      country_code: countryCode.toLowerCase(),
      type_id: serviceTypeId,
      oss_enabled: oss,
    };
    const response = await this.post<unknown, { data: ServiceResponse }>(`/environments/${envId}/services`, data);
    return new Service(response?.data);
  };

  public updateService = async (serviceId: string, envId: string, oss: boolean) => {
    const data = { oss_enabled: oss };
    const response = await this.patch<unknown, { data: ServiceResponse }>(
      `/environments/${envId}/services/${serviceId}`,
      data,
    );
    return new Service(response?.data);
  };

  public renewService = async (envId: string, serviceId: string) => {
    const data = { action: 'credentials' };
    const response = await this.patch<unknown, { data: ServiceResponse }>(
      `/environments/${envId}/services/${serviceId}`,
      data,
    );
    return new Service(response?.data);
  };

  public uploadSalesforceCertificate = async (envId: string, serviceId: string, file: File) => {
    const headers = new Headers();
    headers.append('Authorization', this.headers['Authorization']);
    headers.append(CONFIRMATION_HEADER, this.headers[CONFIRMATION_HEADER]);
    headers.append(COUNTRY_HEADER, this.countryCode || '');
    headers.append('X-CSRF-TOKEN', cookiesService.getCookie(COOKIES_TYPES.CSRF, document.cookie) || '');
    const body = new FormData();
    body.append('file', file, file.name);

    const requestOptions = {
      method: 'POST',
      headers,
      body,
      redirect: 'follow' as RequestRedirect,
    };

    const response = await fetch(
      `${IC_WD_SERVER_API}/environments/${envId}/services/${serviceId}/upload-cert`,
      requestOptions,
    );

    return (await response?.json()) as { message: string };
  };

  public createEncryptionKey = async (envId: string, countryCode: string, params: CreateEncryptionKeyFormInputs) => {
    const preparedParams = prepareNewEncryptionKeyPayload(params);
    const data = await this.post<EncryptionKeyRequestBody, EncryptionKeyResponse>(
      `/v2/environments/${envId}/secret_keys/${countryCode}/create`,
      preparedParams,
    );
    return new EncryptionKey(data);
  };

  public rotateEncryptionKey = async (envId: string, countryCode: string, params: RotateEncryptionKeyFormInputs) => {
    const preparedParams = prepareNewEncryptionKeyPayload(params);
    const data = await this.post<EncryptionKeyRequestBody, EncryptionKeyResponse>(
      `/v2/environments/${envId}/secret_keys/${countryCode}/rotate`,
      preparedParams,
    );
    return new EncryptionKey(data);
  };

  public updateEncryptionKey = async (envId: string, countryCode: string, name: string, rotationPeriod: number) => {
    const payload = {
      name: name,
      rotation_period: rotationPeriod,
    };
    return this.patch<{ name: string; rotation_period: number }, EncryptionKeyResponse>(
      `/v2/environments/${envId}/secret_keys/${countryCode}`,
      payload,
    );
  };

  public updateCompanySettings = (name: string, country: string): Promise<CompanyUpdateResponse> => {
    return this.patch<CompanyBaseInterface, CompanyUpdateResponse>('/company', {
      name,
      country,
    });
  };

  public confirmEmail = (token: string) => {
    return this.get<Response>(`/confirm-email/${token}`);
  };

  public changeEmail = (email: string, recaptcha: string) => {
    return this.post<{ email: string; recaptcha: string }, unknown>('/email/change', { email, recaptcha });
  };

  public confirmOldEmail = (token: string) => {
    return this.post<{ token: string }, Response>('/email/confirm/old', { token });
  };

  public confirmNewEmail = (token: string) => {
    return this.post<{ token: string }, Response>('/email/confirm/new', { token });
  };

  public removeMember = (memberId: string) => {
    return this.delete<void>(`/users/${memberId}`);
  };

  public changeMemberRole = (memberId: string, role: string) => {
    return this.patch<{ role: string }, void>(`/users/${memberId}`, { role });
  };

  public transferMemberOwnership = (memberId: string, password: string) => {
    return this.patch(`/users/${memberId}/ownership`, { password });
  };

  public inviteMember = (email: string, recaptcha: string, resend?: boolean) => {
    return this.post<InviteMemberRequest, InviteMemberResponse>('/invites', {
      email,
      resend: !!resend,
      recaptcha,
    });
  };

  public cancelInvite = (token: string) => {
    return this.delete<void>(`/invites/${token}`);
  };

  public createBorderConfigV2 = (envId: string, serviceId: string, configData: BorderConfigV2) => {
    const cfg = convertBorderConfigForBackend(configData);
    return this.post<BorderConfigDataInputForBackend, BorderConfigV2>(
      `/environments/${envId}/services/${serviceId}/border_cfg`,
      cfg,
    );
  };

  public updateBorderConfigV2 = (
    envId: string,
    serviceId: string,
    borderConfigId: string,
    configData: BorderConfigV2,
  ) => {
    const cfg = convertBorderConfigForBackend(configData);
    return this.patch<BorderConfigDataInputForBackend, BorderConfigV2>(
      `/environments/${envId}/services/${serviceId}/border_cfg/${borderConfigId}`,
      cfg,
    );
  };

  public deleteBorderConfigV2 = async (envId: string, serviceId: string, borderConfigId: string) => {
    return await this.delete<void>(`/environments/${envId}/services/${serviceId}/border_cfg/${borderConfigId}`);
  };

  public createPaymentGateway = async (
    environmentId: string | null,
    countryCode: string,
    paymentProvider: string,
    paymentGatewayName: string,
    paymentGatewayEndpoint: string,
    paymentGatewayMethod: string,
    paymentProviderType?: string,
  ) => {
    const body = {
      payment_provider_id: paymentProvider,
      environment_id: environmentId === 'autogenerated' ? null : environmentId,
      title: paymentGatewayName,
      app_endpoint: paymentGatewayEndpoint,
      country: countryCode,
      request_method: paymentGatewayMethod,
    };

    return this.post<CreatePaymentVaultBodyInterface, CreatePaymentVaultInterface>(
      `/v2/pgs${paymentProviderType === 'braintree' ? '/braintree' : ''}`,
      body,
    );
  };

  public deletePaymentGateway = async (id: string, paymentProviderType?: string) => {
    if (paymentProviderType === 'braintree') {
      return this.delete(`/v2/pgs/braintree/${id}`);
    }
    return this.delete(`/v2/pgs/${id}`);
  };

  public deleteEmailGateway = (id: string) => {
    const data = { email_gateway_id: id };
    return this.delete('/v2/egs', data);
  };

  public createEmailGateway = async (
    environmentId: string | null,
    countryCode: string,
    emailGatewayName: string,
    smtpHost: string,
    smtpPort: string,
    smtpUsername: string,
    smtpPassword: string,
  ) => {
    const body = {
      title: emailGatewayName,
      host: smtpHost,
      port: smtpPort,
      username: smtpUsername,
      password: smtpPassword,
      country: countryCode?.toLowerCase(),
      environment_id: environmentId !== 'autogenerated' ? environmentId : null,
    };

    return this.post<typeof body, CreateEmailGatewayInterface>('/v2/egs', body);
  };

  public updateEmailGateway = async (
    id: string,
    smtpHost: string,
    smtpPort: number,
    smtpUsername?: string,
    smtpPassword?: string,
  ) => {
    const body = {
      host: smtpHost,
      port: smtpPort,
      ...(smtpUsername ? { username: smtpUsername } : {}),
      ...(smtpPassword ? { password: smtpPassword } : {}),
    };

    return this.patch<typeof body, UpdateEmailGatewayInterface>(`/v2/egs/${id}`, body);
  };

  public logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(`${IC_WD_SERVER_API}/auth/v2/logout`);
  };

  public fetchConfirmationStatus = async () => {
    const response = await this.get<{ ttl: number }>('/auth/v2/login/status');
    return response?.ttl;
  };

  public sendConfirmTokenToEmail = () => {
    return this.post('/auth/code', {});
  };

  public exchangeConfirmCodeToToken = (code: string, useRecoveryCode?: boolean) => {
    const data = { token: code };
    const path = useRecoveryCode ? '/auth/lookup/verify/within' : '/auth/code/check';
    return this.post<{ token: string }, ICheckCodeResponse>(path, data);
  };

  private TOTP_ENDPOINT = '/auth/totp';

  public fetchTotpSeed = async () => {
    const { totp_seed } = await this.get<{ totp_seed: string }>(this.TOTP_ENDPOINT);
    return totp_seed;
  };

  public enableTotp = async (code: string) => {
    return await this.post<{ token: string }, { msg: string }>(this.TOTP_ENDPOINT, { token: code });
  };

  public disableTotp = async () => {
    return await this.delete(this.TOTP_ENDPOINT, {});
  };

  public fetchRecoveryCodes = async () => {
    const response = await this.post<unknown, { lookup_secrets: string[] }>('/auth/lookup', {});
    return response?.lookup_secrets;
  };

  public fetchPasswordChallenge = async (): Promise<string | undefined> => {
    const response = await this.get<{ redirect: string }>('/auth/password/challenge');
    return response?.redirect;
  };

  public resetMemberPassword = (orgId: RouterQueryItem, userId: string, email: string) => {
    if (!orgId) return;
    const data = { email, user_uuid: userId, company_uuid: orgId?.toString() };
    return this.post<{ email: string; company_uuid: string }, void>('/auth/password/change/request', data);
  };

  // SSR section
  public checkSsr = <T>(
    func: (ctx: ServerSidePropsContextType, obj?: { props: T }) => Promise<GetServerSidePropsResult<T>>,
  ) => {
    return (ctx: ServerSidePropsContextType, obj?: GetServerSidePropsResult<unknown>) => {
      if (isRedirectResultSsr(obj)) {
        return { redirect: obj.redirect };
      }
      if (isNotFoundResultSsr(obj)) {
        return { notFound: obj.notFound };
      }
      if (isPropsResultSsr<T>(obj)) {
        return func(ctx, obj);
      }
      return func(ctx);
    };
  };

  public fetchUserSsr = async (ctx: GetServerSidePropsContext): Promise<CurrentUserResponse> => {
    const res = await this.get<CurrentUserResponse>('/me', undefined, undefined, ctx);
    return { ...res, $TypeID: 'CurrentUser' };
  };

  public logoutSsr = (errorStatus?: number) => {
    const redirectUrl = new URL(`${IC_WD_SERVER_API}/auth/v2/logout`);
    if (errorStatus) redirectUrl.searchParams.append('errorStatus', errorStatus.toString());
    return {
      redirect: {
        permanent: false,
        destination: redirectUrl.toString(),
      },
    };
  };

  public ssrErrorHandler = async <T>(err: unknown): Promise<GetServerSidePropsResult<T>> => {
    if (err instanceof Error) {
      return {
        redirect: {
          permanent: false,
          destination: `/500?name=${err.name}&message=${err.message}`,
        },
      };
    } else if (err instanceof Response) {
      const { errors } = (await err.json()) as ServerErrorInterface;

      return {
        redirect: {
          permanent: false,
          destination: `/500?name=${errors?.[0]?.title}&message=${errors?.[0]?.source}`,
        },
      };
    } else {
      throw err;
    }
  };

  public fetchPaymentProvidersSsr = this.checkSsr<{ paymentProviders: PaymentProviderInterface[] }>(
    async (ctx, obj) => {
      try {
        const response = await this.get<PaymentProviderInterface[]>(
          '/v2/pgs/payment_providers',
          undefined,
          undefined,
          ctx,
        );
        return { ...obj, props: { ...obj?.props, paymentProviders: response } };
      } catch (err) {
        return this.ssrErrorHandler(err);
      }
    },
  );

  public fetchPaymentVaultSsr = this.checkSsr<{ paymentVault: PaymentVaultInterface }>(async (ctx, obj) => {
    try {
      const id = parseQueryParam(ctx.query.id);
      const type = parseQueryParam(ctx.query.type);
      if (!id) throw new Error('ID parameter is missing');
      if (!type) throw new Error('Type parameter is missing');
      const response = await this.get<PaymentVaultInterface>(
        `/v2/pgs${type === 'braintree' ? '/braintree' : ''}/${id}`,
        undefined,
        undefined,
        ctx,
      );
      return { ...obj, props: { ...obj?.props, paymentVault: response } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchPaymentVaultsSsr = this.checkSsr<{ paymentVaults: PaymentVaultInterface[] }>(async (ctx, obj) => {
    try {
      const response = await Promise.all([
        this.get<PaymentVaultInterface[]>('/v2/pgs/braintree', undefined, undefined, ctx),
        this.get<PaymentVaultInterface[]>('/v2/pgs', undefined, undefined, ctx),
      ]);
      return { ...obj, props: { ...obj?.props, paymentVaults: response.flat() } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchUsageTotalsByEnvSsr = this.checkSsr<{ usageTotalsData: UsageTotalsInterface[] }>(async (ctx, obj) => {
    try {
      const orgId = parseQueryParam(ctx.query.orgId);
      const envId = parseQueryParam(ctx.query.envId);
      const startDate = parseQueryParam(ctx.query.startDate);
      if (!orgId) throw new Error('Organization ID parameter is missing');
      if (!envId) throw new Error('Environment ID parameter is missing');
      if (!startDate) throw new Error('Start date parameter is missing');
      const data = { last_update_time: formatISO(new Date(startDate)) };
      const response = await this.get<UsageTotalsResponseInterface>(
        `/v2/companies/${orgId}/environments/${envId}/usage/totals`,
        data,
        undefined,
        ctx,
      );

      return { ...obj, props: { ...obj?.props, usageTotalsData: response?.data } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchUsageByEnvSsr = this.checkSsr<{ usage: ICountryDataV2[] }>(async (ctx, obj) => {
    try {
      const orgId = parseQueryParam(ctx.query.orgId);
      const envId = parseQueryParam(ctx.query.envId);
      const startDate = parseQueryParam(ctx.query.startDate);
      const endDate = parseQueryParam(ctx.query.endDate);
      if (!orgId) throw new Error('Organization ID parameter is missing');
      if (!envId) throw new Error('Environment ID parameter is missing');
      if (!startDate) throw new Error('Start date parameter is missing');
      if (!endDate) throw new Error('End date parameter is missing');
      const data = { start_time: startDate, end_time: endDate };

      const response = await this.get<IUsageDataByEnvV2>(
        `/v2/companies/${orgId}/environments/${envId}/usage`,
        data,
        undefined,
        ctx,
      );
      return { ...obj, props: { ...obj?.props, usage: response?.data } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEnvironmentsSsr = this.checkSsr<{ environments: EnvironmentResponseData[] }>(async (ctx, obj) => {
    try {
      let environments = await this.get<EnvironmentResponseData[]>('/environments', undefined, undefined, ctx);
      environments = environments.map(env => ({ ...env, $TypeID: 'Environment' }));
      return { ...obj, props: { ...obj?.props, environments: environments?.filter(v => !v.removed_at) } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchWithRemovedEnvironmentsSsr = this.checkSsr<{ environments: EnvironmentResponseData[] }>(
    async (ctx, obj) => {
      try {
        let environments = await this.get<EnvironmentResponseData[]>('/environments', undefined, undefined, ctx);
        environments = environments.map(env => ({ ...env, $TypeID: 'Environment' }));
        return { ...obj, props: { ...obj?.props, environments } };
      } catch (err) {
        return this.ssrErrorHandler(err);
      }
    },
  );

  public fetchEnvironmentSsr = this.checkSsr<{ environment: EnvironmentResponseData }>(async (ctx, obj) => {
    try {
      const envId = ctx?.query?.envId;
      if (!envId) throw new Error('Environment ID is not defined');

      const environment = await this.get<EnvironmentResponseData>(
        `/environments/${envId.toString()}`,
        undefined,
        undefined,
        ctx,
      );
      environment.$TypeID = 'Environment';

      return { ...obj, props: { ...obj?.props, environment } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEncKeysCountriesSsr = this.checkSsr<{ countries: CountryResponseInterface[] }>(async (ctx, obj) => {
    try {
      const response = await this.get<CountriesResponseInterface>(
        '/secret_keys/available_countries?type=mid',
        undefined,
        undefined,
        ctx,
      );

      return { ...obj, props: { ...obj?.props, countries: sortCountriesArray(response.countries) } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchRecoveryCodesSsr = this.checkSsr<{ recoveryCodes: string[] }>(async (ctx, obj) => {
    try {
      const response = await this.post<unknown, { lookup_secrets: string[] }>('/auth/lookup', {}, undefined, ctx);

      return { ...obj, props: { ...obj?.props, recoveryCodes: response.lookup_secrets } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchMembersSsr = this.checkSsr<{ members: MemberResponseInterface[] }>(async (ctx, obj) => {
    try {
      const response = await this.get<{ users: MemberResponseInterface[] }>('/users', undefined, undefined, ctx);
      const members = response.users.map(v => ({ ...v, $TypeID: 'Member' }));
      return { ...obj, props: { ...obj?.props, members } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchInvitesSsr = this.checkSsr<{ invites: InviteResponseInterface[] }>(async (ctx, obj) => {
    try {
      const response = await this.get<{ invites: InviteResponseInterface[] }>('/invites', undefined, undefined, ctx);
      const invites = response.invites.map(v => ({ ...v, $TypeID: 'Invite' }));
      return { ...obj, props: { ...obj?.props, invites } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchBorderConfigsSsr = this.checkSsr<{ borderConfigs: BorderConfigV2[] }>(async (ctx, obj) => {
    try {
      const serviceId = parseQueryParam(ctx.query.serviceId);
      const envId = parseQueryParam(ctx.query.envId);

      const response = await this.get<BorderConfigResponse[]>(
        `/environments/${envId}/services/${serviceId}/border_cfg`,
        undefined,
        undefined,
        ctx,
      );

      const borderConfigs = response.map(config => convertBorderConfigForForm(config));
      return { ...obj, props: { ...obj?.props, borderConfigs } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchBorderConfigSsr = this.checkSsr<{ borderConfig: BorderConfigV2 }>(async (ctx, obj) => {
    try {
      const serviceId = parseQueryParam(ctx.query.serviceId);
      const envId = parseQueryParam(ctx.query.envId);
      const configurationId = parseQueryParam(ctx.query.configurationId);

      const response = await this.get<BorderConfigResponse>(
        `/environments/${envId}/services/${serviceId}/border_cfg/${configurationId}`,
        undefined,
        undefined,
        ctx,
      );
      const borderConfig = convertBorderConfigForForm(response);
      return { ...obj, props: { ...obj?.props, borderConfig } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEncryptionKeysSsr = this.checkSsr<{ encryptionKeys: EncryptionKeyResponse[] }>(async (ctx, obj) => {
    try {
      const envId = parseQueryParam(ctx.query.envId);
      const countryCode = parseQueryParam(ctx.query.countryCode);

      const response = await this.get<EncryptionKeyResponse[]>(
        `/v2/environments/${envId}/secret_keys/${countryCode}`,
        undefined,
        undefined,
        ctx,
      );
      const encryptionKeys = response.map(v => ({ ...v, $TypeID: 'EncryptionKey' }));
      return { ...obj, props: { ...obj?.props, encryptionKeys } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchServiceTypesSsr = this.checkSsr<{ serviceTypes: ServiceTypeResponse[] }>(async (ctx, obj) => {
    try {
      const response = await this.get<{ data: ServiceTypeResponse[] }>('/services/types', undefined, undefined, ctx);
      const serviceTypes = response?.data?.map(v => ({ ...v, $TypeID: 'ServiceType' }));
      return { ...obj, props: { ...obj?.props, serviceTypes } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEmailGatewaysSsr = this.checkSsr<{ emailGateways: EmailGatewayInterface[] }>(async (ctx, obj) => {
    try {
      const emailGateways = await this.get<EmailGatewayInterface[]>('/v2/egs', undefined, undefined, ctx);
      return { ...obj, props: { ...obj?.props, emailGateways } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEmailGatewaySsr = this.checkSsr<{ emailGateway: EmailGatewayInterface }>(async (ctx, obj) => {
    try {
      const id = parseQueryParam(ctx.query.id);
      const emailGateway = await this.get<EmailGatewayInterface>(`/v2/egs/${id}`, undefined, undefined, ctx);
      return { ...obj, props: { ...obj?.props, emailGateway } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEmailGatewayCountriesSsr = this.checkSsr<{ emailGatewayCountryCodes: string[] }>(async (ctx, obj) => {
    try {
      const emailGatewayCountryCodes = await this.get<string[]>('/v2/egs/countries', undefined, undefined, ctx);
      return { ...obj, props: { ...obj?.props, emailGatewayCountryCodes } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchCountriesSsr = this.checkSsr<{ countries: CountryResponseInterface[] }>(async (ctx, obj) => {
    try {
      const response = await this.get<CountriesResponseInterface>('/countries', undefined, undefined, ctx);
      const countries = response.countries.map(v => ({ ...v, $TypeID: 'Country' }));
      return { ...obj, props: { ...obj?.props, countries: countries } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });
}

export default new ApiClient();
