import React from 'react';
import { EmotionCache } from '@emotion/cache';
import { CacheProvider, Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AppProps } from 'next/app';

import { CurrentUser } from 'app/api/models/CurrentUser';
import UserActivityComponent from 'app/components/common/UserActivityComponent';
import AppLayout from 'app/components/layout/AppLayout';
import NextHead from 'app/components/nextjs/NextHead';
import { theme } from 'app/theme-mui';
import { EXCLUDED_FROM_LAYOUT } from 'app/utils/constants';
import Providers from 'app/utils/context/Providers';
import useI18nConfigurator from 'app/utils/customHooks/useI18nConfigurator';
import createModels from 'app/utils/models';
import { configureRecaptchaNet } from 'app/utils/recaptcha';
import configureSentry from 'app/utils/sentry';
import createEmotionCache from 'app/utils/styles/createEmotionCache';
import { configureNprogress, nProgressCSS } from 'app/utils/styles/nprogress';

import '../../styles/globals.css';
import '../../styles/flags.css';

configureNprogress();
configureRecaptchaNet();
configureSentry();

const clientSideEmotionCache = createEmotionCache();

interface ExtendedAppProps extends AppProps {
  pageProps: object;
  emotionCache?: EmotionCache;
}

const App = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
  router,
}: ExtendedAppProps): JSX.Element => {
  useI18nConfigurator();

  const renderComponent = () => {
    const props = createModels<object>(pageProps);
    const user = (props as unknown as { user: CurrentUser })?.user;
    if (user instanceof CurrentUser && !EXCLUDED_FROM_LAYOUT[router.pathname]) {
      return (
        <UserActivityComponent>
          <AppLayout user={user}>
            <Component {...props} />
          </AppLayout>
        </UserActivityComponent>
      );
    }

    return <Component {...props} />;
  };

  return (
    <CacheProvider value={emotionCache}>
      <Global styles={nProgressCSS} />
      <NextHead />
      <MuiThemeProvider theme={theme}>
        <Providers>
          <CssBaseline />
          {renderComponent()}
        </Providers>
      </MuiThemeProvider>
    </CacheProvider>
  );
};

export default App;

App.getInitialProps = () => ({});
