import Country from 'app/api/models/Country';
import { BillingPlan } from 'app/types/billing';
import { CompanyInterfaceResponse, ICompanyLimits } from 'app/types/company';

class Company {
  public id: string;
  public name: string;
  public country?: Country;
  public role: string;
  public plan: BillingPlan;
  public samlId?: string;
  public joinedAt: Date;
  public limits: ICompanyLimits;

  constructor(data: unknown) {
    if (isCompanyResponse(data)) {
      this.id = data.id;
      this.name = data.name;
      this.country = data.country_code ? new Country(data.country_code) : undefined;
      this.role = data.role;
      this.plan = data.plan;
      this.samlId = data.sso_id;
      this.joinedAt = new Date(data.joined_at);
      this.limits = data.limits;
    } else {
      throw new Error('Data is not CompanyResponse');
    }
  }
}

const isCompanyResponse = (data: unknown): data is CompanyInterfaceResponse => {
  const d = data as CompanyInterfaceResponse;
  return (
    d?.id !== undefined &&
    d?.name !== undefined &&
    d?.joined_at !== undefined &&
    d?.role !== undefined &&
    d?.plan !== undefined
  );
};

export default Company;
