import type { ParsedUrlQuery } from 'querystring';

import { DashboardDataTypes, DashboardViewTypes } from 'app/types/dashboard';
import { IDPEndpoint } from 'app/utils/environmentVariables';

export class Route {
  public pathname: string;
  public query: ParsedUrlQuery;
  constructor(pathname: string, query: ParsedUrlQuery = {}) {
    this.pathname = pathname;
    this.query = Object.entries(query)
      .filter(([, val]) => val !== undefined)
      .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});
  }
  toString = () => {
    const allQueriesArr = Object.entries(this.query);
    const paramsArr = allQueriesArr.filter(([key]) => this.pathname.includes(key));
    const queriesArr = allQueriesArr.filter(([key]) => !this.pathname.includes(key));
    const withReplacedParams = paramsArr.reduce(
      (acc, [key, val]) => acc.replace(`[${key}]`, val as string),
      this.pathname,
    );
    const queryStrArr = queriesArr.map(([key, val]) => `${key}=${val as string}`);

    return withReplacedParams + (queryStrArr.length > 0 ? `?${queryStrArr.join('&')}` : '');
  };
}

class RoutesClass {
  RESIDENT_FUNCTIONS_ROOT = '/environments/[envId]/services/[serviceId]/resident-functions';
  BORDER_ROOT = '/environments/[envId]/services/[serviceId]/border';
  ENC_KEYS_ROOT = '/environments/[envId]/encryption/[countryCode]';
  SERVICES_ROOT = '/environments/[envId]/services';
  SDKS_ROOT = '/environments/[envId]/sdks';

  environment = {
    list: () => new Route('/environments'),
    get: (envId?: string) => new Route('/environments/[envId]', { envId }),
    create: (from?: string, type?: string) => new Route('/environments/create', { from, type }),
    update: (envId?: string) => new Route('/environments/[envId]/update', { envId }),
    delete: (envId?: string) => new Route('/environments/[envId]/delete', { envId }),
    restore: (envId?: string) => new Route('/environments/[envId]/restore', { envId }),
  };
  sdk = {
    list: (envId?: string) => new Route(this.SDKS_ROOT, { envId }),
    create: (envId?: string, from?: string) => new Route(this.SDKS_ROOT + '/create', { envId, from }),
    update: (envId?: string, sdkId?: string) => new Route(this.SDKS_ROOT + '/[sdkId]/update', { envId, sdkId }),
    delete: (envId?: string, sdkId?: string) => new Route(this.SDKS_ROOT + '/[sdkId]/delete', { envId, sdkId }),
  };
  service = {
    list: (envId?: string) => new Route(this.SERVICES_ROOT, { envId }),
    create: (envId?: string, from?: string, type?: string) =>
      new Route(this.SERVICES_ROOT + '/create', { envId, from, type }),
    renew: (envId?: string, serviceId?: string) =>
      new Route(this.SERVICES_ROOT + '/[serviceId]/renew', { envId, serviceId }),
    delete: (envId?: string, serviceId?: string) =>
      new Route(this.SERVICES_ROOT + '/[serviceId]/delete', { envId, serviceId }),
    update: (envId?: string, serviceId?: string) =>
      new Route(this.SERVICES_ROOT + '/[serviceId]/edit', { envId, serviceId }),
  };
  encryptionKey = {
    list: (envId?: string, countryCode?: string) =>
      new Route(this.ENC_KEYS_ROOT, { envId, countryCode: countryCode?.toLowerCase() }),
    create: (envId?: string, countryCode?: string) =>
      new Route(this.ENC_KEYS_ROOT + '/create', { envId, countryCode: countryCode?.toLowerCase() }),
    rotate: (envId?: string, countryCode?: string) =>
      new Route(this.ENC_KEYS_ROOT + '/rotate', { envId, countryCode: countryCode?.toLowerCase() }),
    update: (envId?: string, countryCode?: string, keyId?: string) =>
      new Route(this.ENC_KEYS_ROOT + '/[keyId]/update', { envId, countryCode, keyId }),
  };
  member = {
    list: () => new Route('/members'),
    invite: () => new Route('/members/invite'),
    transfer: () => new Route('/members/transfer-ownership'),
    changeRole: (id?: string) => new Route('/members/[id]/change-role', { id }),
    remove: (id?: string) => new Route('/members/[id]/remove', { id }),
    resetPassword: (id?: string) => new Route('/members/[id]/reset-password', { id }),
  };
  invite = {
    cancel: (id?: string) => new Route('/invites/[id]/cancel', { id }),
    resend: (id?: string) => new Route('/invites/[id]/resend', { id }),
  };
  residentFunction = {
    list: (envId?: string, serviceId?: string) => new Route(this.RESIDENT_FUNCTIONS_ROOT, { envId, serviceId }),
    publish: (envId?: string, serviceId?: string) =>
      new Route(this.RESIDENT_FUNCTIONS_ROOT + '/publish', { envId, serviceId }),
    configure: (envId?: string, serviceId?: string, functionName?: string) =>
      new Route(this.RESIDENT_FUNCTIONS_ROOT + '/[functionName]/configure', { envId, serviceId, functionName }),
    delete: (envId?: string, serviceId?: string, functionName?: string) =>
      new Route(this.RESIDENT_FUNCTIONS_ROOT + '/[functionName]/delete', { envId, serviceId, functionName }),
  };
  dashboard = {
    list: () => new Route('/dashboard'),
    get: (
      orgId?: string,
      envId?: string,
      startDate?: string,
      endDate?: string,
      dataType?: DashboardDataTypes,
      viewType?: DashboardViewTypes,
    ) => new Route('/dashboard/[envId]', { envId, orgId, startDate, endDate, dataType, viewType }),
  };
  emailGateway = {
    list: () => new Route('/email-gateways'),
    create: () => new Route('/email-gateways/create'),
    update: (id?: string) => new Route('/email-gateways/[id]/edit', { id }),
    embed: (id?: string) => new Route('/email-gateways/[id]/embed', { id }),
    delete: (id?: string) => new Route('/email-gateways/[id]/delete', { id }),
  };
  paymentVault = {
    list: () => new Route('/payment-vault'),
    create: () => new Route('/payment-vault/create'),
    embed: (id?: string, type?: string) => new Route('/payment-vault/[id]/embed', { id, type }),
    delete: (id?: string, type?: string) => new Route('/payment-vault/[id]/delete', { id, type }),
  };
  border = {
    list: (envId?: string, serviceId?: string) => new Route(this.BORDER_ROOT, { envId, serviceId }),
    create: (envId?: string, serviceId?: string) => new Route(this.BORDER_ROOT + '/create', { envId, serviceId }),
    import: (envId?: string, serviceId?: string, configurationId?: string) =>
      new Route(this.BORDER_ROOT + '/import', { envId, serviceId, configurationId }),
    view: (envId?: string, serviceId?: string, configurationId?: string) =>
      new Route(this.BORDER_ROOT + '/[configurationId]/view', { envId, serviceId, configurationId }),
    update: (envId?: string, serviceId?: string, configurationId?: string) =>
      new Route(this.BORDER_ROOT + '/[configurationId]/edit', { envId, serviceId, configurationId }),
    delete: (envId?: string, serviceId?: string, configurationId?: string) =>
      new Route(this.BORDER_ROOT + '/[configurationId]/delete', { envId, serviceId, configurationId }),
  };
  salesforce = {
    certificate: (envId?: string, serviceId?: string) =>
      new Route('/environments/[envId]/services/[serviceId]/salesforce/certificate', { envId, serviceId }),
  };
  gettingStarted = {
    list: () => new Route('/getting-started'),
    get: (key?: string, serviceId?: string, step?: string) =>
      new Route('/getting-started/[key]', { key, serviceId, step }),
  };
  plans = {
    list: () => new Route('/plans'),
  };
  profile = {
    list: () => new Route('/profile'),
    mfaApp: () => new Route('/profile/mfa-totp'),
    mfaEmail: () => new Route('/profile/mfa-email'),
    changeEmail: () => new Route('/profile/change-email'),
  };
  company = {
    list: () => new Route('/company'),
    create: () => new Route('/company/create'),
    update: () => new Route('/company/update'),
  };
  email = {
    confirm: () => new Route('/confirm-email/[token]'),
  };
  auth = {
    login: () => new Route('/login'),
    loginSso: () => new Route('/login/sso'),
    logout: () => new Route('/logout'),
  };
  htmlGateway = {
    list: () => new Route('/html-gateway'),
  };
  webFormGateway = {
    list: () => new Route('/web-form-gateway'),
  };
}

export const Routes = new RoutesClass();

export const REDIRECT_MAP: { [key: string]: string } = {
  billing: Routes.plans.list().pathname,
  account: Routes.company.list().pathname,
  product: Routes.gettingStarted.list().pathname,
  owner: Routes.gettingStarted.list().pathname,
  member: Routes.gettingStarted.list().pathname,
};

export const EXTERNAL_LINKS = {
  INCOUNTRY: {
    CONTACT_PRICING: 'https://incountry.com/pricing/#contact-us',
  },
  DOCS: {
    LANDING: 'https://docs.incountry.com',
    PORTAL: 'https://docs.incountry.com/portal/getting-started-portal/',
    PYTHON_SDK: 'https://docs.incountry.com/sdks/python/',
    NODE_JS_SDK: 'https://docs.incountry.com/sdks/nodejs/',
    JAVA_SDK: 'https://docs.incountry.com/sdks/java/',
    C_SHARP_SDK: 'https://docs.incountry.com/sdks/c+/',
    DOCS: 'https://docs.incountry.com/portal/getting-started-portal/',
    SALESFORCE: 'https://docs.incountry.com/salesforce/managing-oauth2-authentication-and-authorization/',
    REST_API: 'https://docs.incountry.com/rest-api/',
    RESIDENT_FUNCTIONS: 'https://docs.incountry.com/serverless/',
    POSTMAN_COLLECTION: 'https://docs.incountry.com/assets/RestAPI_Demo_oAuth.postman_collection.json/',
    SALESFORCE_OVERVIEW: 'https://docs.incountry.com/salesforce/salesforce-overview/',
    SALESFORCE_PACKAGE: 'https://docs.incountry.com/salesforce/getting-started-salesforce#package-installation/',
    PAYMENT_GATEWAYS:
      'https://docs.incountry.com/payment-gateway/#integrating-the-payment-elements-library-into-the-payment-workflow/',
    BORDER: 'https://docs.incountry.com/border/',
    BORDER_CONFIG: 'https://docs.incountry.com/portal/managing-border-configuration/',
    BORDER_CONFIG_REDACTION:
      'https://docs.incountry.com/portal/managing-border-configuration/#example-of-configuring-redaction-rules',
    BORDER_CONFIG_UNREDACTION:
      'https://docs.incountry.com/portal/managing-border-configuration/#example-of-configuring-unredaction-rules',
    SDK_OVERVIEW: 'https://docs.incountry.com/sdks/overview/',
    SDK_GIT: 'https://github.com/incountry',
  },
  IDP: {
    RESET_REQUEST: `${IDPEndpoint}/auth/password/recovery`,
    REGISTER: `${IDPEndpoint} /auth/register`,
    RESET_EMAIL_REQUEST: `${IDPEndpoint} /auth/resend_user_confirm`,
  },
};

export const EXCLUDED_FROM_LAYOUT = {
  [Routes.company.create().pathname]: true,
};
