// 1 level
export interface BorderConfigV2 {
  id: string;
  name: string;
  version: number;
  created_at: string;
  immutable: boolean;
  border_target_endpoint: string;
  synchronization_endpoint: string;
  data: BorderConfigData;
}

// 2 level
export interface BorderConfigData {
  target: string;
  country: string;
  dtk?: string;
  environmentId: string;
  redactions: BorderRedactionRuleInterface[];
  unredactions: BorderUnredactionRuleInterface[];
}

// 3 level
export interface BorderRedactionRuleInterface {
  path: string;
  method: string;
  entityIdPath: string;
  collectionName: string;
  globalEntityId?: boolean;
  entityErrorCorrectionFieldPath: string;
  strategies: BorderRedactionStrategyInterface[];
  operationMode?: BorderRuleOperationMode;
}

export interface BorderUnredactionRuleInterface {
  path: string;
  method: string;
  collections: BorderUnredactionRuleCollectionInterface[];
}

// 4 level
export interface BorderRedactionStrategyInterface {
  path: string;
  strategy: StrategyTypes;
  strategyOptions?: {
    value?: string;
    length?: number;
    storeField?: boolean;
  };
  searchableKey?: string;
}

export enum BorderRuleOperationMode {
  processing = 'processing',
  processingAndStorage = 'processingAndStorage',
}

export interface BorderUnredactionRuleCollectionInterface {
  name: string;
  entityIdPath: string;
  globalEntityId?: boolean;
  entityErrorCorrectionFieldPath: string;
  isErrorCorrectionFieldIdx?: string;
  strategies: BorderUnredactionStrategyInterface[];
}

// 5 level
export interface BorderUnredactionStrategyInterface {
  path: string;
  originalPath?: string;
  isErrorCorrectionField?: boolean;
}

// transform
export type BorderConfigPayload = Pick<BorderConfigV2, 'name'> & Omit<BorderConfigData, 'country' | 'environmentId'>;

type BorderRedactionRuleResponse = Omit<BorderRedactionRuleInterface, 'searchable'> & {
  searchable: { [key: string]: string };
};

export type BorderConfigDataResponse = Omit<BorderConfigData, 'redactions'> & {
  redactions: BorderRedactionRuleResponse[];
};

export type BorderConfigDataInputForBackend = Pick<BorderConfigV2, 'name'> &
  Omit<BorderConfigData, 'redactions' | 'country' | 'environmentId'> & { redactions: BorderRedactionRuleResponse[] };

export type BorderConfigResponse = Omit<BorderConfigV2, 'data'> & { data: BorderConfigDataResponse };

export enum StrategyTypes {
  email = 'email',
  alphaNumeric = 'alphaNumeric',
  alphaNumericLowerCase = 'alphaNumericLowerCase',
  alphaPrepended = 'alphaPrepended',
  one = 'one',
  zero = 'zero',
  numeric = 'numeric',
  dateISO = 'dateISO',
  defaultDateISO = 'defaultDateISO',
  fixed = 'fixed',
  emailPersistent = 'emailPersistent',
  alphaNumericPersistent = 'alphaNumericPersistent',
  plain = 'plain',
}
