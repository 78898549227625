import { Redirect } from 'next';
import { ParsedUrlQuery } from 'querystring';

export const parseQueryParam = (qParam: string | string[] | undefined): string => {
  if (typeof qParam === 'string') {
    return qParam;
  }
  return 'undefinedQueryParameter';
};

export const getPathnameFromNextContext = (url: string, query: ParsedUrlQuery): string => {
  const queries = Object.entries(query);
  return queries.reduce((acc, [key, val]) => acc?.replace(val as string, `[${key}]`), url.split('?')?.[0]);
};

export const getNextRedirect = (destination: string, permanent?: boolean): { redirect: Redirect } => ({
  redirect: {
    destination,
    permanent: !!permanent,
  },
});
