import React, { SyntheticEvent, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Alert, AlertColor, Snackbar } from '@mui/material';

import VerificationAlert from 'app/components/common/VerificationAlert';

type CustomAlertColor = AlertColor | 'verification';

export type OpenSnackbarType = (message: string, variant: CustomAlertColor, autoHideDuration?: number | null) => void;

interface SnackbarState {
  open: boolean;
  message: string;
  variant?: CustomAlertColor;
  autoHideDuration?: number | null;
}

interface SnackbarReturnType {
  renderSnackbar: () => JSX.Element;
  openSnackbar: (message: string, variant?: CustomAlertColor, autoHideDuration?: number | null | undefined) => void;
  closeSnackbar: () => void;
}

const DEFAULT_STATE = { open: false, message: '', autoHideDuration: 5000 };
const DATA_TESTID_ROOT = 'Snackbar-root';
const DATA_TESTID_MESSAGE = 'Snackbar-message';

export const useSnackbar = (): SnackbarReturnType => {
  const [state, setState] = useState<SnackbarState>(DEFAULT_STATE);

  const handleClose = (_event: Event | SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setState(DEFAULT_STATE);
  };

  const openSnackbar = useCallback(
    (message: string, variant?: CustomAlertColor, autoHideDuration?: number | null | undefined) =>
      setState({ open: true, message, variant, autoHideDuration }),
    [],
  );

  const closeSnackbar = useCallback(() => setState(DEFAULT_STATE), []);

  const renderSnackbar = () => {
    const { open, variant, autoHideDuration = 6000, message } = state;

    return variant ? (
      <Snackbar
        data-testid={DATA_TESTID_ROOT}
        open={open}
        autoHideDuration={autoHideDuration}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        {variant === 'verification' ? (
          <VerificationAlert data-testid={DATA_TESTID_MESSAGE} onClose={handleClose}>
            {message}
          </VerificationAlert>
        ) : (
          <StyledAlert
            data-testid={DATA_TESTID_MESSAGE}
            severity={variant}
            variant='filled'
            sx={{ width: '100%' }}
            onClose={handleClose}
          >
            {message}
          </StyledAlert>
        )}
      </Snackbar>
    ) : (
      <Snackbar
        data-testid={DATA_TESTID_ROOT}
        open={open}
        autoHideDuration={autoHideDuration}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={<span data-testid={DATA_TESTID_MESSAGE}>{message}</span>}
        onClose={handleClose}
      />
    );
  };

  return { renderSnackbar, openSnackbar, closeSnackbar };
};

const StyledAlert = styled(Alert)`
  color: white;
  white-space: pre-wrap;
`;
