import { createTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { colors } from 'app/theme-colors';

export const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, Helvetica, Tahoma',
    h1: { fontSize: 36 },
    h2: { fontSize: 32 },
    h3: { fontSize: 24 },
    h4: { fontSize: 20 },
    h5: { fontSize: 18 },
    h6: { fontSize: 16 },
    body1: { fontSize: 14 },
    body2: { fontSize: 13 },
  },
  palette: {
    primary: { main: colors.primary.main, dark: colors.primary.dark, contrastText: '#ffffff' },
    secondary: { main: colors.secondary.main, dark: colors.secondary.dark, contrastText: '#ffffff' },
    text: {
      primary: colors.text.primary,
      secondary: alpha(colors.text.primary, 0.7),
      disabled: colors.gray.main,
    },
    background: {
      default: colors.alternative.dark,
      paper: colors.white,
    },
    success: { main: colors.status.success },
    info: { main: colors.status.info },
    error: { main: colors.status.error },
    warning: { main: colors.status.warning.color },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {},
        standardWarning: {
          alignItems: 'center',
          color: colors.status.warning.color,
          backgroundColor: alpha(colors.status.warning.background, 0.25),
          '& $icon': {
            color: colors.status.warning.color,
          },
        },
      },
    },
  },
});
