import PortalConfig from 'app/api/models/PortalConfig';
import { Roles } from 'app/types/auth';
import { CurrentUserResponse } from 'app/types/user';
import { Routes } from 'app/utils/constants';

const commonAccessList: { [key: string]: Roles[] } = {
  [Routes.gettingStarted.list().pathname]: [
    Roles.owner,
    Roles.administrator,
    Roles.auditor,
    Roles.manager,
    Roles.member,
  ],
  [Routes.gettingStarted.get().pathname]: [
    Roles.owner,
    Roles.administrator,
    Roles.auditor,
    Roles.manager,
    Roles.member,
  ],
  [Routes.dashboard.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.dashboard.get().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.plans.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor, Roles.manager, Roles.member],
  [Routes.environment.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.environment.get().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.environment.create().pathname]: [Roles.owner, Roles.administrator],
  [Routes.environment.update().pathname]: [Roles.owner, Roles.administrator],
  [Routes.environment.delete().pathname]: [Roles.owner, Roles.administrator],
  [Routes.environment.restore().pathname]: [Roles.owner, Roles.administrator],
  [Routes.sdk.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.sdk.create().pathname]: [Roles.owner, Roles.administrator],
  [Routes.sdk.update().pathname]: [Roles.owner, Roles.administrator],
  [Routes.sdk.list().pathname]: [Roles.owner, Roles.administrator],
  [Routes.service.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.service.create().pathname]: [Roles.owner, Roles.administrator],
  [Routes.service.renew().pathname]: [Roles.owner, Roles.administrator],
  [Routes.service.delete().pathname]: [Roles.owner, Roles.administrator],
  [Routes.service.update().pathname]: [Roles.owner, Roles.administrator],
  [Routes.encryptionKey.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.encryptionKey.create().pathname]: [Roles.owner, Roles.administrator],
  [Routes.encryptionKey.update().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.encryptionKey.rotate().pathname]: [Roles.owner, Roles.administrator],
  [Routes.residentFunction.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor, Roles.manager],
  [Routes.residentFunction.publish().pathname]: [Roles.owner, Roles.administrator],
  [Routes.residentFunction.configure().pathname]: [Roles.owner, Roles.administrator],
  [Routes.residentFunction.delete().pathname]: [Roles.owner, Roles.administrator],
  [Routes.salesforce.certificate().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.border.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.border.create().pathname]: [Roles.owner, Roles.administrator],
  [Routes.border.update().pathname]: [Roles.owner, Roles.administrator],
  [Routes.border.view().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.border.import().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.border.delete().pathname]: [Roles.owner, Roles.administrator],
  [Routes.paymentVault.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.paymentVault.embed().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.paymentVault.create().pathname]: [Roles.owner, Roles.administrator],
  [Routes.paymentVault.delete().pathname]: [Roles.owner, Roles.administrator],
  [Routes.emailGateway.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.emailGateway.embed().pathname]: [Roles.owner, Roles.administrator, Roles.auditor],
  [Routes.emailGateway.create().pathname]: [Roles.owner, Roles.administrator],
  [Routes.emailGateway.delete().pathname]: [Roles.owner, Roles.administrator],
  [Routes.emailGateway.update().pathname]: [Roles.owner, Roles.administrator],
};

export const generalAccessList: { [key: string]: Roles[] } = {
  ...commonAccessList,
  [Routes.company.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor, Roles.manager],
  [Routes.company.update().pathname]: [Roles.owner],
  [Routes.company.create().pathname]: [Roles.owner],
  [Routes.profile.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor, Roles.manager, Roles.member],
  [Routes.member.list().pathname]: [Roles.owner, Roles.administrator, Roles.auditor, Roles.manager],
  [Routes.member.remove().pathname]: [Roles.owner],
  [Routes.member.resetPassword().pathname]: [Roles.owner],
  [Routes.member.changeRole().pathname]: [Roles.owner, Roles.administrator],
  [Routes.member.transfer().pathname]: [Roles.owner],
  [Routes.invite.cancel().pathname]: [Roles.owner, Roles.manager],
  [Routes.invite.resend().pathname]: [Roles.owner, Roles.manager],
  [Routes.member.invite().pathname]: [Roles.owner, Roles.manager],
  [Routes.profile.mfaApp().pathname]: [Roles.owner, Roles.administrator, Roles.auditor, Roles.manager, Roles.member],
  [Routes.profile.mfaEmail().pathname]: [Roles.owner, Roles.administrator, Roles.auditor, Roles.manager, Roles.member],
  [Routes.profile.changeEmail().pathname]: [
    Roles.owner,
    Roles.administrator,
    Roles.auditor,
    Roles.manager,
    Roles.member,
  ],
};

export const samlAccessList: { [key: string]: Roles[] } = { ...commonAccessList };

export const isRouteAllowed = (user: CurrentUserResponse, pathname: string): boolean => {
  if (user.companies.length === 0 && pathname === Routes.company.create().pathname) return true;

  const defaultCompany = user.companies.filter(v => v.id === user.company_id)?.[0];
  const acl = defaultCompany.sso_id ? samlAccessList : generalAccessList;
  const roleHasAccess = acl[pathname]?.includes(defaultCompany.role);
  const configHasAccess = PortalConfig.acl[pathname] !== false;

  return roleHasAccess && configHasAccess;
};
