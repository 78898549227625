/* eslint-disable sonarjs/no-duplicate-string */
export const translation = {
  SignIn: {
    signIn: 'Sign In',
    signUp: 'Sign Up',
    forgotPassword: 'Forgot password?',
  },

  ProfileAccountSettings: {
    account: 'Account',
    resetPassword: 'Reset Password',
    type: 'Type',
  },
  ProfileSessions: {
    terminateSession: 'Terminate Session',
    logout: 'Log out',
  },
  ProfileApplication: {
    mfaAuthentication: 'Two-factor authentication',
    email: 'Email',
    authenticationApp: 'Authentication App',
    hintApp:
      'You can switch to authorization of your actions on Portal through the mobile authentication app (e.g. Google Authenticator). Every half minute the app generates a confirmation code that you can use to confirm your actions on {{brandingTitle}} Portal.',
    hintEmail:
      'You can switch to authorization of your actions on Portal through emails containing a confirmation code. Portal will send an email with a confirmation code every time you need to authorize a performed action on {{brandingTitle}} Portal.',
    switchToEmail: 'Switch to Email Authentication',
    switchToApp: 'Switch to Authentication App',
    current: 'Current',
  },
  ProfilePage: {
    profile: 'Profile',
    user: 'User',
  },
  PlansPage: {
    current: 'Current',
    myCompany: 'My Company',
    choosePlan: 'Choose a suitable Subscription Plan',
    subscriptionPlans: 'Subscription Plans',
    trial: 'Trial',
    professional: 'Professional',
    enterprise: 'Enterprise',
    recordsMonth: 'Records / month',
    bandwidth: 'Bandwidth',
    ApiCalls: 'API Calls',
    professionalApiCalls: 'API calls per record per month; additional API calls $0.01',
    recordMinimum: 'Record Minimum per country',
    multiTenant: 'Multi-tenant',
    singleTenant: 'Single-tenant',
    StandardTerms: 'Standard click through terms',
    saasNotIncluded: 'SaaS services not included',
    yourCurrentPlan: 'Your Current Plan',
    select: 'Select',
    contactUs: 'Contact Us',
    dedicatedHost: 'Dedicated and isolated',
    customStorage: 'Storage, bandwidth and API calls',
    saasIncluded: 'SaaS service, including Salesforce and ServiceNow',
    custom: 'Custom',
    services: 'Services',
    host: 'Host',
    pageTitle: 'Subscription Plan',
    pageDescription:
      'After the trial period you need to subscribe to data residency services provided by {{brandingTitle}}. By default, all the newly registered users (and their organizations) start with a trial plan which has significant limitations.',
  },
  PlanCancelPage: {
    profile: 'Profile',
    confirmCancelSubscription: 'Confirm Cancel Subscription',
    cancelSubscription: 'Cancel Subscription',
    plans: 'Plans',
    confirm: 'Confirm',
    keepSubscription: 'Keep Subscription',
    areYouSure: 'Are you sure you want to cancel your Professional plan subscription?',
    thisWillCancelSubscription:
      'This action will switch your subscription plan back to Trial which has significant limitations.',
  },
  PlanCreatePage: {
    cardInformationAndVerification: 'Card Information & Verification',
    parameters: 'Parameters',
    subscriptionConfirmation: 'Subscription Confirmation',
    createSubscription: 'Create Subscription',
    plans: 'Plans',
    emailAddress: 'Email Address',
    emailAddressDescription:
      'Enter your email address. By default, {{brandingTitle}} Portal uses the email address of your user’s profile.',
    holderName: 'Holder Name',
    holderNameDescription: 'Enter the name of the card holder that is displayed on your payment card.',
    creditCardNumber: 'Credit Card Number',
    creditCardNumberDescription: 'Enter the credit card number.',
    expirationDate: 'Expiration Date',
    expirationDateDescription: 'Enter the valid period (month and year) of your payment card that is displayed on it.',
    cvc: 'CVC',
    cvcDescription: 'Enter the CVC protection number that is located on the back of your payment card.',
    pageTitle: 'Subscription Plan',
    pageDescription:
      'After the trial period you need to subscribe to data residency services provided by {{brandingTitle}}. By default, all the newly registered users (and their organizations) start with a trial plan which has significant limitations.',
  },
  ConfirmationCodeComponent: {
    enterTotpCode: 'Enter the code from the authentication app',
    enterEmailCode: 'Enter the code from your email',
    useVerificationCode: 'Use Verification Code',
    enterRecoveryCode: 'Enter Recovery Code',
    verificationCode: 'Verification Code',
    confirmationNotice: 'Please verify the entered information one more time before going further.',
    previousCodeIsValid: 'Previously entered verification code is still valid',
    verificationCodeSent: 'Verification code has been sent to your email',
    enterAppVerificationCode: 'Enter the verification code from the mobile authentication app',
    verificationCodeAlreadySent:
      'The verification code has already been sent to you. Please check your email box and enter the recently received confirmation code',
  },
  Errors: {
    verificationCodeIsInvalid: 'Verification code is invalid',
  },
  EnvironmentsPage: {
    environments: 'Environments',
    SDKs: 'SDK credentials',
    services: 'Services',
    createNewEnvironment: 'New Environment',
    title: 'What is Environment?',
    maxEnvironmentLimitReached: 'Maximum limit of environments has been reached',
    showPendingRemoval: 'Show environments pending removal',
    description:
      'Environment is a data storage entity that determines the operational context for data residency services provided by the {{brandingTitle}} platform. The environment allows you to regulate access to your data stores on the {{brandingTitle}} platform.',
    subDescription:
      'By default, {{brandingTitle}} Portal creates a default environment of the Development type. You can create additional environments as needed. Within each environment, you can have multiple SDKs and services that you can use for storing regulated data on the {{brandingTitle}} platform.',
  },
  EnvironmentCreatePage: {
    myCompany: 'My Company',
    environments: 'Environments',
    createNewEnvironment: 'New Environment',
    create: 'Create',
    name: 'Name',
    parameters: 'Parameters',
    verification: 'Verification',
    labelDesc: 'Enter the name of the environment to identify it among other environments.',
    type: 'Type',
    typeDesc:
      'Select the type of environment which you want to set up (Dev, Test, Staging, Integration, U.A.T., or Production).',
    confirmation: 'Confirmation',
    confirmationDesc: 'Enter the confirmation code from your email or authentication app.',
    title: 'What is Environment?',
    description:
      'Environment is a term in {{brandingTitle}} Platform which determines the operational context for data residency services. The environment allows you to regulate access to your data stores in {{brandingTitle}} Platform.',
    next: 'Next',
  },
  CreateEnvironmentForm: {
    type: 'Type',
    name: 'Name',
    createNewEnvironment: 'New Environment',
    updateEnvironment: 'Update Environment',
    back: 'Back',
  },
  EnvironmentPage: {
    myCompany: 'My Company',
    environmentSetup: 'Environment Setup',
    environments: 'Environments',
    createNewEnvironment: 'New Environment',
    SDKs: 'SDK credentials',
    activeSdks: 'active SDK credentials',
    generateSdk: 'Generate SDK credentials',
    services: 'Services',
    servicesCreated: 'services created',
    createService: 'Create Service',
    encryptionKeys: 'Encryption Keys',
    keysCreated: 'keys created',
    name: 'Name',
    type: 'Type',
    edit: 'Edit',
    delete: 'Delete',
    maxSdkLimitReached: 'Maximum limit of SDKs has been reached',
    descriptionTitle: 'What is Environment?',
    description:
      'Environment is a term in {{brandingTitle}} Platform which determines the operational context for data residency services. The environment allows you to regulate access to your data stores in {{brandingTitle}} Platform.',
    id: 'ID',
    restoreEnvitonmentAlert:
      'The removal of this environment is scheduled on {{date}}. All the SDK credentials, services, encryption keys, and records stored within this environment are temporarily locked. They will be automatically wiped with the environment. If the environment was deleted accidentally, you can restore it by clicking the button below.',
    restoreEnvitonmentSubAlert:
      'The complete removal of the environment may require a significant amount of time depending on the amount of stored data and current load. Upon the removal of the environment and all its associated data, you will receive an email notification.',
    restoreEnvironment: 'Restore Environment',
    cantRestoreEnvironment: 'Environments limit is exceeded. Delete unnecessary environments and try again.',
  },
  EnvironmentUpdatePage: {
    myCompany: 'My Company',
    environments: 'Environments',
    updateEnvironment: 'Update Environment',
    update: 'Update',
    parameters: 'Parameters',
    verification: 'Verification',
    descriptionTitle: 'What is Environment?',
    description:
      'Environment is a term in {{brandingTitle}} Platform which determines the operational context for data residency services. The environment allows you to regulate access to your data stores in {{brandingTitle}} Platform.',
    name: 'Name',
    labelDesc: 'Enter the name of the environment to identify it among other environments.',
    type: 'Type',
    typeDesc:
      'Select the type of environment which you want to set up (Dev, Test, Staging, Integration, U.A.T., or Production).',
    confirmation: 'Confirmation',
    confirmationDesc: 'Enter confirmation code from your email or authenticator app.',
    next: 'Next',
  },
  EnvironmentsDeletePage: {
    myCompany: 'My Company',
    environments: 'Environments',
    deleteEnvironment: 'Delete Environment',
    delete: 'Delete',
    verification: 'Verification',
    parameters: 'Parameters',
    areYouSure: 'Do you really want to delete this environment',
    thisWillDelete:
      'This action will schedule `{{name}}` environment to be deleted. \nAll the SDK credentials, services, encryption keys, and records stored within this environment will be deleted. If the environment was scheduled for deletion accidentally, you can restore it by clicking Restore button on environment page.',
  },
  CreateBillingCustomerForm: {
    creditCardInformation: 'Credit Card Information',
    back: 'Back',
    upgrade: 'Upgrade',
    holderName: 'Holder Name',
    email: 'Email',
    subscriptionPlan: 'Subscription Plan',
    stripeNotLoadedError: "Stripe.js hasn't loaded yet",
    subscriptionWasSuccessfullyCreated: 'The subscription has been created successfully',
    creditCardNumber: 'Credit Card Number',
    expirationDate: 'Expiration Date',
    cvc: 'CVC',
  },
  SdksPage: {
    SDKs: 'SDK credentials',
    environments: 'Environments',
    createNewEnvironment: 'New Environment',
    name: 'Name',
    created: 'Created',
    changeSdkLabel: 'Rename SDK Credentials',
    deleteSdk: 'Delete SDK Credentials',
    createNewSdk: 'New SDK Credentials',
    myCompany: 'My Company',
    pageTitle: 'Working with SDK credentials',
    maxSdkLimitReached: 'Max SDK limit reached',
    pageDescription:
      'SDK credentials are a pair of Client ID and Client Secret that you can use to get access to data residency services of the {{brandingTitle}} platform within the allocated environment. All SDK credentials are associated with a specific environment within which they were created.',
  },
  SdkCreatePage: {
    newSdk: 'New SDK Credentials',
    environments: 'Environments',
    name: 'Name',
    back: 'Back',
    next: 'Next',
    myCompany: 'My Company',
    parameters: 'Parameters',
    SDKs: 'SDK credentials',
    verification: 'Verification',
    create: 'Create',
    credentials: 'Credentials',
    gettingStarted: 'Getting Started',
    integrateSdk: 'Integrate SDK into your application',
    enterSdkLabel: 'Enter the name for SDK credentials to easily distinguish one pair of credentials from another.',
    enterVerificationCode:
      'A verification code protects from an accident removal of an SDK. You can change parameters of verification codes on the Profile page.',
    pageTitle: 'Working with SDK credentials',
    pageDescription:
      'SDK credentials are a pair of Client ID and Client Secret that you can use to get access to data residency services of the {{brandingTitle}} platform within the allocated environment. All SDK credentials are associated with a specific environment within which they were created.',
  },
  SdkUpdatePage: {
    SDKs: 'SDK credentials',
    environments: 'Environments',
    name: 'Name',
    back: 'Back',
    next: 'Next',
    myCompany: 'My Company',
    parameters: 'Parameters',
    enterSdkLabel: 'Enter the name for SDK credentials to easily distinguish one pair of credentials from another.',
    verification: 'Verification',
    update: 'Update',
    renameSdkCredentials: 'Rename SDK Credentials',
    credentials: 'Credentials',
    pageTitle: 'Working with SDK credentials',
    pageDescription:
      'SDK credentials are a pair of Client ID and Client Secret that you can use to get access to data residency services of the {{brandingTitle}} platform within the allocated environment. All SDK credentials are associated with a specific environment within which they were created.',
    changeLabel: 'Changing SDK Name will not change Client ID or Secret.',
  },
  SdkDeletePage: {
    SDKs: 'SDK credentials',
    environments: 'Environments',
    myCompany: 'My Company',
    name: 'Name',
    back: 'Back',
    confirmation: 'Confirmation',
    confirm: 'Confirm',
    verification: 'Verification',
    parameters: 'Parameters',
    next: 'Next',
    delete: 'Delete',
    deleteSdk: 'Delete SDK Credentials',
    areYouSure: 'Do you really want to delete these SDK credentials?',
    thisWillDelete:
      'This will permanently delete the `{{name}}` SDK credentials.\nThis action cannot be undone.\n\nRemoval of SDK credentials may partially or fully break operation of your service or system that uses the {{brandingTitle}} SDK as data requests will not be authorized.',
  },
  CreateSdkForm: {
    name: 'Name',
    required: 'Required',
  },
  UpdateSdkForm: {
    name: 'Name',
    back: 'Back',
    required: 'Required',
  },
  ShowSdkCredentials: {
    sdkName: 'SDK Name Credentials',
    sdkId: 'Client ID',
    sdkSecret: 'Client Secret',
    environmentId: 'Environment ID',
    copy: 'Copy',
    close: 'Close',
    copyToClipboard: 'Copy To Clipboard',
    downloadText: 'Download Text',
    makeSureToCopy: 'Make sure to copy this information now. You will no longer be able to see it again!',
    environmentIdIsCopied: 'Environment ID copied successfully',
  },
  CompanyPage: {
    companySettings: 'Company Settings',
    companyName: 'Company Name',
    country: 'Country',
    myCompany: 'My Company',
    update: 'Update',
    subscriptionPlans: 'Subscription Plans',
    membersAndInvites: 'Members & Invites',
    transferOwnership: 'Transfer Ownership',
    companyOwnership: 'Company Ownership',
    manageMembers: 'Manage Members',
    changePlan: 'Change Plan',
    members: 'Members',
  },
  CompanyUpdatePage: {
    updateCompanySettings: 'Update Company Settings',
    update: 'Update',
    parameters: 'Parameters',
    verification: 'Verification',
    next: 'Next',
    cancel: 'Cancel',
    pageDescTitle: 'Your Company',
    pageDescDetails:
      'You can modify the organization which you represent and the country where you are located. Changing these parameters does not impact the overall work with the {{brandingTitle}} platform.',
    myCompany: 'My Company',
  },
  ServicesPage: {
    myCompany: 'My Company',
    services: 'Services',
    environments: 'Environments',
    createNewEnvironment: 'New Environment',
    residentFunctions: 'Resident functions',
    manageConfigurations: 'Manage configurations',
    name: 'Name',
    created: 'Created',
    type: 'Type',
    country: 'Country',
    renewService: 'Renew Service',
    deleteService: 'Delete Service',
    createNewService: 'New Service',
    editService: 'Edit Service',
    pageTitle: 'Working with Services',
    pageDescription:
      'A service is an entity which represents a connection point which can be used to integrate your system with other components of the {{brandingTitle}} Platform.',
    uploadCertificate: 'Upload Certificate',
    replaceCertificate: 'Replace Certificate',
  },
  ServiceCreatePage: {
    myCompany: 'My Company',
    services: 'Services',
    newService: 'New Service',
    environments: 'Environments',
    environment: 'Environment',
    create: 'Create',
    pageTitle: 'Working with Services',
    pageDescription: `A service is an entity which represents a connection point which can be used to integrate your system
      with other components of the {{brandingTitle}} Platform.`,
    parameters: 'Parameters',
    next: 'Next',
    verification: 'Verification',
    credentials: 'Credentials',
    enterVerificationCode:
      'Please, check the service parameters one more time and confirm them with a verification code',
    type: 'Type',
    name: 'Name',
    country: 'Country',
    alibabaCloudOss: 'Alibaba Cloud OSS',
    paramDescription1:
      'Select the service type from the list. You will no longer be able to change the type of service after creation.',
    paramDescription2: 'Enter the name of the service.',
    paramDescription3: 'Select the country where service setup.',
    paramDescription4:
      'Move the toggle right to activate the object storage service to store attachments on Alibaba Cloud.',
    paramDescription5:
      'The environment defines the operational context for storing regulated data on the {{brandingTitle}} platform. You can use environments to regulate access to stored data.',
    instructions: 'Instructions',
    setupBorderConfiguration: 'Setup Border Configuration',
    publishResidentFunction: 'Publish Resident Function',
    restapiGuideTitle: 'Communicate regulated data through the REST API',
    salesforceGuideTitle: 'Integrate Salesforce with {{brandingTitle}}',
    gettingStarted: 'Getting Started',
  },
  ServiceDeletePage: {
    myCompany: 'My Company',
    deleteService: 'Delete Service',
    services: 'Services',
    environments: 'Environments',
    verification: 'Verification',
    parameters: 'Parameters',
    delete: 'Delete',
    areYouSure: 'Are you sure you want to delete service?',
    thisWillDelete: 'This will permanently delete the `{{name}}` service.',
    pageTitle: 'Working with Services',
    pageDescription: `A service is an entity which represents a connection point which can be used to integrate your system
      with other components of the {{brandingTitle}} Platform.`,
  },
  ServiceRenewPage: {
    renewService: 'Renew Service',
    services: 'Services',
    environments: 'Environments',
    renew: 'Renew',
    areYouSure: 'Do you really want to renew this service?',
    actionCantBeUndone:
      'This action cannot be undone. This will create new credentials for the {{name}} service and deprecate the old ones.',
    pageTitle: 'Working with Services',
    pageDescription: `A service is an entity which represents a connection point which can be used to integrate your system
      with other components of the {{brandingTitle}} Platform.`,
    parameters: 'Parameters',
    verification: 'Verification',
    credentials: 'Credentials',
    close: 'Close',
    back: 'Back',
  },
  CreateServiceForm: {
    type: 'Type',
    name: 'Name',
    country: 'Country',
    required: 'This field is required',
    maxLength: 'The maximum length of an company name is {{maxLength}} characters',
    environmentId: 'Environment ID',
    createNewEnvironment: 'New Environment',
    environment: 'Environment',
    alicloudOss: 'Alibaba Cloud OSS',
    serviceUsed:
      '{{type}} service for the {{country}} already exists. You can create only one service of each type for one country within the same environment.',
  },
  EditSalesforceServicePage: {
    SDKs: 'SDK credentials',
    environments: 'Environments',
    name: 'Name',
    back: 'Back',
    next: 'Next',
    myCompany: 'My Company',
    parameters: 'Parameters',
    enterSdkLabel: 'Enter the name for SDK credentials to easily distinguish one pair of credentials from another.',
    verification: 'Verification',
    update: 'Update',
    services: 'Services',
    editService: 'Edit Service',
    credentials: 'Credentials',
    pageTitle: 'Working with SDK credentials',
    pageDescription:
      'SDK credentials are a pair of Client ID and Client Secret that you can use to get access to data residency services of the {{brandingTitle}} platform within the allocated environment. All SDK credentials are associated with a specific environment within which they were created.',
    changeLabel: 'Changing SDK Name will not change Client ID or Secret.',
    type: 'Type',
    country: 'Country',
    environment: 'Environment',
    alibabaCloudOss: 'Alibaba Cloud OSS',
    paramDescription1:
      'Select the service type from the list. You will no longer be able to change the type of service after creation.',
    paramDescription2: 'Enter the name of the service.',
    paramDescription3: 'Select the country where service setup.',
    paramDescription4:
      'Move the toggle right to activate the object storage service to store attachments on Alibaba Cloud.',
    paramDescription5:
      'The environment defines the operational context for storing regulated data on the {{brandingTitle}} platform. You can use environments to regulate access to stored data.',
  },
  ShowServiceCredentials: {
    sdkName: 'SDK Name Credentials',
    sdkId: 'Client ID',
    sdkSecret: 'Client Secret',
    environmentId: 'Environment ID',
    restApiAddress: 'Rest API Address',
    proxyAddress: 'Proxy Address',
    copy: 'Copy',
    copyToClipboard: 'Copy To Clipboard',
    downloadText: 'Download Text',
    cloakCredentials: 'Cloak Credentials',
    makeSureToCopy: 'Make sure to copy this information now. You will no longer be able to see it again!',
    environmentIdIsCopied: 'Environment ID copied successfully',
    subjectClaim: 'Subject Claim',
    salesforceAlarmText:
      'Please use the subject claim to define OAuth authorization in Salesforce when setting up a connection to the {{brandingTitle}} platform.',
    ossAlert1: 'To finalize activation of the object storage service (OSS) for this service, please contact ',
    ossAlert2: 'Alibaba Cloud team',
    ossAlert3: ' and provide them with the following subject claim ',
  },
  NoEnvironmentsPlaceholder: {
    createNewEnvironment: 'New Environment',
    noEnvironments: 'No environments available',
    noEnvironmentsNotification:
      'It seems that you have not created any environments yet. Please add at least one environment to proceed with the management of SDKs, services, and in-app extensions on {{brandingTitle}} Portal.',
  },
  DashboardEnvPage: {
    dashboard: 'Dashboard',
    gettingStarted: 'Getting Started?',
    professional: 'Professional',
    bandwidth: 'Bandwidth',
    transactions: 'Transactions',
    changeSubscriptionPlan: 'Change Subscription Plan',
    companySettings: 'Company Settings',
    members: 'Members & Invitations',
    logout: 'Log out',
    profile: 'Profile',
    dataDistribution: 'Data Distribution',
    noDataProvided: 'No Data Provided',
    files: 'Files',
    records: 'Records',
    filesSize: 'Files Size',
    recordsSize: 'Records Size',
    selectedEnvironment: 'Selected Environment',
    createNewEnvironment: 'New Environment',
    dataType: 'Data Type',
  },
  DashboardUsageTable: {
    country: 'Country',
    deviation: 'Deviation',
    bandwidth: 'Bandwidth',
    numberOfTransactions: 'Transactions',
    numberOfReads: 'Reads',
    numberOfWrites: 'Writes',
    numberOfDeletes: 'Deletes',
    numberOfErrors: 'Errors',
  },
  DashboardUsageTotalsTable: {
    country: 'Country',
    files: 'Files',
    records: 'Records',
    recordsSize: 'Records Size',
    filesSize: 'Files Size',
  },
  DatePickerComponent: {
    dataActual: 'Data actual as of selected date',
    pickADate: 'Pick a Date',
  },
  DateTimeRangePicker: {
    startDate: 'Start Date',
    endDate: 'End Date',
    pickADateRange: 'Pick a Date Range',
  },
  Map: {
    noDataProvided: 'No Data Provided',
    usageWarning: 'Usage data is updated every 5 minutes',
  },
  MapTooltip: {
    bandwidth: 'Bandwidth',
    transactions: 'Transactions',
    country: 'Country',
    files: 'Files',
    records: 'Records',
    recordsSize: 'Records Size',
    filesSize: 'Files Size',
  },
  EncryptionKeysPage: {
    version: 'Version',
    myCompany: 'My Company',
    country: 'Country',
    encryptionKeys: 'Encryption Keys',
    month_one: '{{count}} month',
    month_other: '{{count}} months',
    createNewEnvironment: 'New Environment',
    environments: 'Environments',
    createNewEncryptionKey: 'New Encryption Key',
    sseWarning:
      'To activate the server-side encryption (SSE) when using the {{brandingTitle}} SDK, please create an encryption key for the country and add the `X-Encrypted-Storage: true` header into your data requests.',
    status: 'Status',
    created: 'Created',
    active: 'Active',
    deprecated: 'Deprecated',
    name: 'Name',
    type: 'Type',
    month: 'Month',
    incountryKeyMgmt: '{{brandingTitle}} Managed',
    manualKeyMgmtAws: 'Manual: AWS',
    createNewVersion: 'New Version',
    pageTitle: 'Working with Encryption',
    rotationPeriod: 'Rotation Period',
    pageDescription:
      'The {{brandingTitle}} Portal lets you create encryption keys that are used for data encryption by {{brandingTitle}} REST API. You need to generate new secret keys for security purposes when you are extensively writing data records to {{brandingTitle}} Platform.',
  },
  EncryptionKeyCreatePage: {
    create: 'Create',
    myCompany: 'My Company',
    environments: 'Environments',
    encryptionKeys: 'Encryption Keys',
    keyType: 'Key Type',
    verification: 'Verification',
    parameters: 'Parameters',
    newVersionOfKey: 'New Version of Encryption Key',
    newEncryptionKey: 'New Encryption Key',
    newKeyVersionConfirmation:
      'Do you really want to create a new version of the encryption key? Creating a new version will mark all other keys as deprecated. New records will be encrypted using this new version of the encryption. Other encryption keys will be preserved for decryption of old records.',
    chooseKeyManagementType:
      'Please choose which type of key management to use for the current environment and country',
    encryptionKeysTitle: 'Working with Encryption',
    encryptionKeysDescription:
      'The {{brandingTitle}} Portal lets you create encryption keys that are used for data encryption by {{brandingTitle}} REST API. You need to generate new encryption keys for security purposes when you are extensively writing data records to the {{brandingTitle}} Platform.',
    next: 'Next',
  },
  EncryptionKeyUpdatePage: {
    update: 'Update',
    myCompany: 'My Company',
    environments: 'Environments',
    encryptionKeys: 'Encryption Keys',
    verification: 'Verification',
    parameters: 'Parameters',
    encryptionKeyName: 'Name',
    encryptionKeyNameDescription: 'Specify name of the encryption key.',
    updateEncryptionKey: 'Update Encryption Key',
    verificationCodeTitle: 'Verification Code',
    verificationCodeDescription: 'Verification code adds an extra layer of security to your account.',
    encryptionKeysUpdateTitle: 'Rotation Period',
    encryptionKeysUpdateDescription:
      'When you use the {{brandingTitle}}-managed encryption keys, you should consider that key rotation policies are applied automatically. You can define the appropriate key rotation period upon expiry of which a new version of the secret key is generated.',
    next: 'Next',
  },
  EncryptionKeyRotatePage: {
    rotate: 'Rotate',
    myCompany: 'My Company',
    environments: 'Environments',
    encryptionKeys: 'Encryption Keys',
    verification: 'Verification',
    parameters: 'Parameters',
    encryptionKeyName: 'Name',
    encryptionKeyNameDescription: 'Specify name of the encryption key.',
    rotateEncryptionKey: 'Rotate Encryption Key',
    verificationCodeTitle: 'Verification Code',
    verificationCodeDescription: 'Verification code adds an extra layer of security to your account.',
    encryptionKeysRotateTitle: 'Rotation Period',
    encryptionKeysRotateDescription:
      'When you use the {{brandingTitle}}-managed encryption keys, you should consider that key rotation policies are applied automatically. You can define the appropriate key rotation period upon expiry of which a new version of the secret key is generated.',
    next: 'Next',
  },
  UpdateEncryptionKeyForm: {
    name: 'Name',
    required: 'Required',
    rotationPeriod: 'Rotation Period',
    rotationPeriodNotice: 'Rotation of encryption keys does not occur immediately and may take up to 24 hours.',
    oneMonth: '1 month (30 days)',
    twoMonths: '2 months (60 days)',
    sixMonths: '6 months (180 days)',
    oneYear: '1 year (360 days)',
  },
  CreateEncryptionKeyForm: {
    createNewVersion: 'New Version',
    createNewEncryptionKey: 'New Encryption Key',
    managementType: 'Key Management Type',
    name: 'Name',
    label: 'Name',
    keyType: 'Key Type',
    rotationPeriod: 'Rotation Period',
    oneMonth: '1 month (30 days)',
    twoMonths: '2 months (60 days)',
    sixMonths: '6 months (180 days)',
    oneYear: '1 year (360 days)',
    incountryKeyMgmt: '{{brandingTitle}} Managed',
    manualKeyMgmtAws: 'Manual: AWS',
    plainKey: 'Plain Encryption Key (base64-encoded)',
    region: 'Region',
    encryptedKey: 'Encrypted Key',
    customerManagedKey: 'Customer Managed Key (CMK) ARN',
    iamAccessKeyId: 'IAM Access Key ID',
    iamSecretKey: 'IAM Secret Key',
    notValidBase64: 'Not valid Base64 encoded key',
  },
  GettingStartedPage: {
    gettingStarted: 'Getting Started',
    restapiGuideTitle: 'Communicate regulated data through REST API',
    restapiGuideDescription: 'Communicate and manage regulated data with REST API methods.',
    sdkGuideTitle: 'Integrate SDK into your application',
    sdkGuideDescription: 'Implement complex scenarios to handle regulated data with SDKs.',
    salesforceGuideTitle: 'Integrate Salesforce with {{brandingTitle}}',
    salesforceGuideDescription: 'Try out-of-the-box service with Salesforce for handling regulated data.',
  },
  GettingStartedGuidePage: {
    createEnvironment: 'Create an environment',
    createSdk: 'Generate SDK Credentials',
    mappingYourData: 'Mapping Your Data',
    processingData: 'Processing data',
    pythonSdk: 'Python SDK',
    nodejsSdk: 'Node.js SDK',
    javaSdk: 'Java SDK',
    cSharpSdk: 'C# SDK',
    processCsvData: 'Process CSV Data',
    generateSecureKeys: 'Generate Secure Keys',
    incountryRestapiDocumentation: '{{brandingTitle}} Rest API Documentation',
    downloadCollection: 'Download Collection',
    createSalesforceService: 'Create Salesforce Service',
    installingPackagesAtSalesforce: 'Install package',
    checkDocsButtonLabel: 'Check documentation',
    createRestApiService: 'Create REST API Service',
    restApiDocumentation: '{{brandingTitle}} REST API Documentation',
    gettingStarted: 'Getting Started',
    restapi: 'Communicate regulated data through REST API',
    sdk: 'Integrate SDK into your application',
    salesforce: 'Integrate Salesforce with {{brandingTitle}}',
    downloadSdk: 'Download SDK',
    guideSdkTitle: 'Integrate SDK into your application',
    guideSdkStep1Title: 'Generate SDK Credentials',
    guideSdkStep1Description:
      'SDK credentials include Client ID and Client Secret that you can use to get access to the data residency services of the {{brandingTitle}} platform within the selected environment. You can create multiple copies of SDK credentials, but please keep them safe and secure.',
    guideSdkStep2Title: 'Explore the SDK documentation',
    guideSdkStep2Description:
      'Save the generated SDK credentials and use them within the SDK to authorize data requests from your application to the {{brandingTitle}} platform. Be careful with them and keep them safe. If some SDK credentials were accidentally compromised, please delete them and create a new pair. Check our SDK documentation to better understand capabilities and advantages of the {{brandingTitle}} platform.',
    guideSdkStep3Title: 'Integrate the SDK into your application',
    guideSdkStep3Description:
      'Download the SDK for the appropriate programming language, such as Java, Python, Node.js, and C#. The SDK provides the essential capabilities to manage regulated data records and supports custom encryption keys, as well as key rotation. Quickly initialize the SDK within your application and use the available methods for development of custom business logic to handle regulated data through the {{brandingTitle}} platform within your application.',
    guideRestapiTitle: 'Communicate regulated data through the REST API',
    guideRestapiStep1Title: 'Create REST API service',
    guideRestapiStep1Description:
      'Service is a connection setup that you can use to integrate with the {{brandingTitle}} platform through REST API. While creating a service, select the service type (e.g. REST API) and country for saving data. Once created, you will get a set of access credentials including the Client ID and Secret that you can further use to manage data records on the {{brandingTitle}} platform with REST API.',
    guideRestapiStep2Title: 'Perform data requests with REST API',
    guideRestapiStep2Description:
      'Implement data requests to create, read, update, or delete regulated data stored on the {{brandingTitle}} platform. Develop more complex scenarios to create a bulk of new data records or handle attachments with sensitive data for specific data records. Check the REST API documentation to get details on each data request and its structure.',
    guideRestapiStep3Title: 'Test REST API requests',
    guideRestapiStep3Description:
      'Download the Postman collection with the ready-to-use REST API requests. Add your Client ID and Secret and specify the endpoint for the access token acquiring. Add the endpoint pointing to the country where you want to manage regulated data records. Perform a test run and then proceed with implementing production scenarios.',
    guideSalesforceTitle: 'Integrate Salesforce with {{brandingTitle}}',
    guideSalesforceStep1Title: 'Integrate {{brandingTitle}} and Salesforce',
    guideSalesforceStep1Description:
      'Service is a connection setup that you can use to integrate Salesforce with the {{brandingTitle}} platform. While creating a service, select the service type (e.g. Salesforce) and country for saving data. Once created, you will get a set of access credentials including the Client ID and Secret that you can further use to connect Salesforce to the {{brandingTitle}} platform.',
    guideSalesforceStep2Title: 'Explore capabilities of Salesforce service',
    guideSalesforceStep2Description:
      'The {{brandingTitle}} platform provides three data regulation models: redaction, restriction, and replication. Each model was designed to address specific data regulations. Explore our documentation on how to get started with the {{brandingTitle}} Data Residency for Salesforce package and manage regulated data in Salesforce.',
    guideSalesforceStep3Title: 'Install the package in Salesforce',
    guideSalesforceStep3Description:
      'The {{brandingTitle}} Data Residency package is distributed as a standard package that you can install on your Salesforce instance from AppExchange. After installation, set up a secure connection between Salesforce and the {{brandingTitle}} platform, then proceed with the package configuration according to our documentation. Follow the steps from our checklists to verify the correct setup of the package.',
    guideSalesforceStep4Title: 'Set up the package and select the regulated data for localization',
    guideSalesforceStep4Description:
      'The {{brandingTitle}} platform provides three data regulation models: redaction, restriction, and replication. Each model was designed to address specifics of data distribution and localization in a particular country. Check the local laws and regulations of the data origin country and choose the best model for your regulated data.',
    defaultEnvironmentAlertStart: 'The Salesforce service will be created within  ',
    defaultEnvironmentAlertEnd: 'environment.',
  },
  GuideItem: {
    start: 'Start',
  },
  MembersPage: {
    email: 'Email',
    role: 'Role',
    updated: 'Updated',
    inviteNewMember: 'Invite New Member',
    membersAndInvitations: 'Members & Invitations',
    owner: 'Owner',
    member: 'Member',
    product: 'Product',
    billing: 'Billing',
    account: 'Account',
    guest: 'Guest',
    inviteIsPending: 'Invite is pending...',
    changeRole: 'Change Role',
    resetMemberPassword: 'Reset Password',
    removeMember: 'Remove Member',
    resendInvite: 'Resend Invite',
    cancelInvite: 'Cancel Invite',
  },
  MemberRemovePage: {
    membersAndInvitations: 'Members & Invitations',
    removeMember: 'Remove Member',
    remove: 'Remove',
    areYouSure: 'Are you sure you want to remove a member from your organization?',
    thisActionWillRemove: 'This action will remove',
    member: 'member from your organization.',
    manageAccess: 'Manage Access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    back: 'Back',
  },
  MemberResetPasswordPage: {
    membersAndInvitations: 'Members & Invitations',
    resetMemberPassword: 'Reset Member Password',
    resetPassword: 'Reset Password',
    areYouSureYouWantToReset: 'Do you really want to reset password of a member?',
    thisActionWillResetPasswordOf: 'This action will send a reset password request email to',
    member: 'member from your organization.',
    manageAccess: 'Manage Access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    back: 'Back',
  },
  MemberChangeRolePage: {
    membersAndInvitations: 'Members & Invitations',
    changeMemberRole: 'Change Member Role',
    roles: 'Roles',
    confirmNewRole: 'Confirm New Role',
    verification: 'Verification',
    parameters: 'Parameters',
    enterVerificationCode:
      'A verification code protects from an accident removal of an SDK. You can change parameters of verification codes on the Profile page.',
    managingRole:
      "By default, all the users' roles within your organization can be managed by the user with the Owner or Account Management role. All the newly registered users receive the Member role. The assigned role defines the actions which the user can perform on {{brandingTitle}} Portal.",
    manageAccess: 'Manage Access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    next: 'Next',
    update: 'Update',
  },
  ChangeMemberRoleForm: {
    role: 'Role',
    owner: 'Owner',
    member: 'Member',
    auditor: 'Configuration Auditor',
    administrator: 'Configuration Administrator',
    manager: 'Account Manager',
    guest: 'Guest',
    changeMemberRole: 'Change Member Role',
    required: 'Required',
  },
  MemberTransferOwnership: {
    companySettings: 'Company Settings',
    parameters: 'Parameters',
    verification: 'Verification',
    transfer: 'Transfer',
    transferOwnership: 'Transfer Ownership',
    confirmTransfer: 'Confirm Transfer',
    manageAccess: 'Manage Access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    enterVerificationCode:
      'Please, check that you transfer ownership to a proper member and confirm this action with a verification code.',
    managingOwnershipTitle: 'Managing Ownership',
    managingOwnershipDesc:
      '{{brandingTitle}} Portal allows only one owner per organization. The owner of the organization can transfer the ownership to another registered user with Configuration Administrator, Configuration Auditor or Account Manager role within the scope of the organization.',
    next: 'Next',
  },
  TransferMemberOwnershipForm: {
    transferOwnership: 'Transfer Ownership',
    member: 'Member',
    password: 'Password',
    required: 'Required',
    role: 'Role',
  },
  InviteResendPage: {
    resendInvite: 'Resend Invite',
    membersAndInvitations: 'Members & Invitations',
    areYouSure: 'Do you really want to resend an invitation?',
    thisActionWillSendTo: 'This action will send an invitation email to',
    memberOfOrganization: 'member from your organization.',
    manageAccess: 'Manage Access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    back: 'Back',
  },
  InviteCancelPage: {
    cancelInvite: 'Cancel Invite',
    membersAndInvitations: 'Members & Invitations',
    areYouSureYouWantToCancel: 'Do you really want to cancel an invitation?',
    thisActionWillCancelInviteSentTo: 'This action will cancel the invite that you have sent to',
    member: 'member earlier.',
    manageAccess: 'Manage Access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    back: 'Back',
  },
  MemberInvitePage: {
    membersAndInvitations: 'Members & Invitations',
    verification: 'Verification',
    parameters: 'Parameters',
    invite: 'Invite',
    inviteNewMember: 'Invite New Member',
    invitationEmail: 'Invitation Email',
    next: 'Next',
    confirmAndInvite: 'Confirm & Invite',
    manageAccess: 'Manage Access',
    pageDescription: '{{brandingTitle}} Portal lets you manage users within your organization and invite new users.',
    invitationDescription:
      'Having the Owner role, you can invite users to {{brandingTitle}} Portal. You can invite new users by sending an automated email with invitation or share an invitation link with them.',
    enterVerificationCode:
      'A verification code protects from an accident removal of an SDK. You can change parameters of verification codes on the Profile page.',
  },
  InviteMemberForm: {
    email: 'Email',
    inviteNewMember: 'Invite New Member',
    back: 'Back',
    required: 'Required',
  },
  ResidentFunctionsPage: {
    myCompany: 'My Company',
    environments: 'Environments',
    createNewEnvironment: 'New Environment',
    services: 'Services',
    residentFunctions: 'Resident functions',
    newFunction: 'New Function',
    execute: 'Execute',
    configure: 'Configure',
    delete: 'Delete',
    name: 'Name',
    created: 'Created',
    updated: 'Updated',
    sdkSecret: 'Client Secret',
    invalidClient: 'You have entered an invalid Client Secret',
    pageTitle: 'Working with Resident functions',
    residentFunctionService: 'Resident Function Service',
    pageDescription:
      'A resident function is a function which allows you to perform remote and compliant operations on regulated data without its leaving the country of origin, so that you do not violate any local requirements for handling personal data.',
  },
  ResidentFunctionExecuteResults: {
    residentFunctions: 'Resident functions',
    environments: 'Environments',
    services: 'Services',
    executeFunction: 'Execute Function',
    result: 'Result',
    exitCode: 'Exit Code',
    exitSignal: 'Exit Signal',
    duration: 'Duration',
    invalidPayload: 'Invalid Payload',
  },
  ResidentFunctionsControlForm: {
    functionName: 'Function Name',
    parameters: 'Parameters',
    countryCode: 'Country Code',
    name: 'Name',
    value: 'Value',
    back: 'Back',
    publish: 'Publish',
    update: 'Update',
    required: 'Required',
    execute: 'Execute',
    addParameter: 'Add Parameter',
    removeParameter: 'Remove Parameter',
    nameCantBeModified: "The resident function's name cannot be modified",
    residentFunctionProductionAlert: 'This resident function will process production data.',
    updateBeforeExecute: 'You changed the function body. Please, update the function before executing it.',
  },
  ResidentFunctionConfigurePage: {
    newFunction: 'New Function',
    publishFunction: 'Publish Function',
    updateFunction: 'Update Function',
    configureFunction: 'Configure Function',
    publish: 'Publish',
    update: 'Update',
    environments: 'Environments',
    services: 'Services',
    residentFunctions: 'Resident functions',
    functionCreated: 'Function "{{name}}" is successfully created',
    functionUpdated: 'Function "{{name}}" is successfully updated',
    beautify: 'Beautify',
    invalidClient: 'You have entered an invalid Client Secret',
  },
  ResidentFunctionPublishPage: {
    newFunction: 'New Function',
    publishFunction: 'Publish Function',
    updateFunction: 'Update Function',
    publish: 'Publish',
    update: 'Update',
    environments: 'Environments',
    services: 'Services',
    residentFunctions: 'Resident functions',
    functionCreated: 'Function "{{name}}" is successfully created',
    beautify: 'Beautify',
    invalidClient: 'You have entered an invalid Client Secret',
  },
  ResidentFunctionDeletePage: {
    myCompany: 'My Company',
    deleteFunction: 'Delete Function',
    services: 'Services',
    environments: 'Environments',
    verification: 'Verification',
    parameters: 'Parameters',
    delete: 'Delete',
    residentFunctions: 'Resident functions',
    areYouSure: 'Are you sure you want to delete this function?',
    thisWillDelete: 'This will permanently delete the `{{name}}` function.',
    invalidClient: 'You have entered an invalid Client Secret',
  },
  MainMenu: {
    companySettings: 'Company Settings',
    members: 'Members & Invitations',
    subscription: 'Subscription',
    start: 'Start',
    profile: 'Profile',
    logout: 'Log out',
    dashboard: 'Dashboard',
    environments: 'Environments',
    inAppExtensions: 'In-app extensions',
    paymentVault: 'Payment Vault',
    emailGateway: 'Email Gateway',
    htmlGateway: 'HTML Gateway',
    webFormGateway: 'Web Form Gateway',
    encryptionKeys: 'Encryption keys',
    complianceAdvisor: 'Compliance advisor',
    support: 'Support',
    gettingStarted: 'Getting Started',
    documentation: 'Documentation',
    portal: 'Portal',
    border: 'Border',
    salesforce: 'Salesforce',
    restApi: 'Rest API',
    pythonSdk: 'Python SDK',
    cSharpSdk: 'C# SDK',
    javaSdk: 'Java SDK',
    nodeJsSdk: 'Node.js SDK',
    contactSupport: 'Contact Support',
  },
  ConfirmEmailPage: {
    welcome: 'Welcome onboard!',
    incountryDescription:
      'Welcome to the {{longBrandingTitleWithAbbreviation}} Portal! Here you can start your journey to the world of data residency and compliance. Create your free account or log in to the {{brandingTitle}} Portal to take advantage of our data residency services for your business.',
    signIn: 'Log in to Portal',
    emailConfirmed: 'Thank you for confirming your email!',
    somethingWentWrong: 'Something went wrong',
  },
  CopyToClipboardButton: {
    copyToClipboard: 'Copy To Clipboard',
  },
  EnhancedTable: {
    rowsPerPage: 'Rows Per Page',
    prevPage: 'Previous Page',
    nextPage: 'Next Page',
    noDataToShow: 'No Data to Show',
  },
  MfaTotpPage: {
    twoFactorAuthentication: 'Two-Factor Authentication Settings',
    TwoFASettings: '2FA Settings',
    profile: 'Profile',
    start: 'Start',
    recoveryCodes: 'Recovery Codes',
    setupTotpApp: 'Setup TOTP App',
    cancel: 'Cancel',
    next: 'Next',
    back: 'Back',
    totp: 'TOTP',
    copyToClipboard: 'Copy To Clipboard',
    downloadCodes: 'Download Codes',
    successfullyCopiedToClipboard: 'Successfully copied to clipboard',
    confirmWithCode: 'Confirm With Code',
    confirmWithCodeDescription:
      'Before configuring the authentication app, please confirm this action with the code that we sent to your email.',
    recoveryCodesDescription:
      'Recovery codes are used to access your account if you cannot receive verification codes. Treat your recovery codes with the same level of attention as you would your password. Download or copy your recovery codes before proceeding with the two-factor authentication setup below.',
    myCompany: 'My Company',
  },
  MfaEmailPage: {
    back: 'Back',
    twoFactorAuthentication: 'Two-Factor Authentication Settings',
    email: 'Email',
    switchToEmail: 'Switch To Email',
    profile: 'Profile',
    areYouSure: 'Do you really want to switch to email?',
    disableTotpAction: 'This action will switch you back to using email as a two-factor authentication (2FA) factor.',
    myCompany: 'My Company',
    mfaTitle: 'Two-factor Authentication',
    mfaDescription:
      'Two-factor authentication is an electronic authentication method that grants access to a website or application to users only after successfully presenting two or more pieces of evidence to an authentication mechanism: knowledge, possession, and inherence.',
  },
  TotpBarcodeComponent: {
    totpApplicationCode: 'TOTP Application Code',
    enterThisTextCode: 'enter this text code',
    scanBarcodeWithYourApp: 'Scan this barcode with your app',
    recoveryCodes: 'Recovery codes',
    twoFactorAuthenticationSettings: 'Two-factor authentication settings',
    back: 'Back',
    confirm: 'Confirm',
    copyToClipboard: 'Copy To Clipboard',
    successfullyCopiedToClipboard: 'Successfully copied to clipboard',
    switchedToOtp: 'Two-factor authentication successfully switched to authenticator app',
    invalidCode: 'Invalid Code',
    qrCodeDescription:
      "Scan the image with an authentication app on your smartphone.\nIf you can't use a QR scanner, enter `{{code}}` code instead.",
    qrCodeDescriptionAfter:
      'After scanning the QR code, the app will display a six-digit code that you need to enter below to enable this authentication method',
    cancel: 'Cancel',
  },
  TotpRecoveryCodes: {
    copyToClipboard: 'Copy To Clipboard',
    downloadCodes: 'Download Codes',
    successfullyCopiedToClipboard: 'Successfully copied to clipboard',
    next: 'Next',
    cancel: 'Cancel',
  },
  IdleTimerComponent: {
    yourSessionIsExpiringIn: 'Your session is about to expire in',
    moveYourMousePointer: 'seconds. Move your mouse pointer to continue remain logged in.',
  },
  CreateCompanyForm: {
    name: 'Name',
    country: 'Country',
    createCompany: 'Create Company',
    required: 'Required',
  },
  CreateCompanyExternalForm: {
    name: 'Name',
    country: 'Country',
    logout: 'Log out',
    required: 'This field is required',
    maxLength: 'The maximum length of an company name is {{maxLength}} characters',
    create: 'Create',
  },
  CreateCompanyPage: {
    createCompany: 'New Company',
    createNewCompany: 'You were excluded from your previous company. To continue, please, create a new one.',
  },
  LoginPage: {
    dataResidency: 'Data Residency-as-a-Service (DRaaS)',
    supporting: 'supporting 90+ countries around the world',
    loginToPortal: 'Log in to portal',
    loginToDCC: 'Log in with my account',
    welcome:
      'Welcome to the {{longBrandingTitle}} Portal! Here you can start your journey to the world of data residency and compliance. Create your free account or log in to the {{brandingTitle}} Portal to take advantage of our data residency services for your business.',
    welcomeDss:
      'Welcome to the {{longBrandingTitle}} Here you can start your journey to the world of data residency and compliance. Create your free account or log in to the {{brandingTitle}} Portal to take advantage of our data residency services for your business.',
    description:
      '{{longBrandingTitleWithAbbreviation}} provides a unique data residency-as-a-service platform that eases your compliance burden, letting you easily access new markets, regardless of data regulations, within tight time frames.',
    descriptionDCC:
      '{{longBrandingTitleDCC}} provides a unique data residency-as-a-service platform that eases your compliance burden, letting you easily access new markets, regardless of data regulations, within tight time frames.',
    resetMyPassword: 'Reset my password',
    createFreeAccount: 'Create free account',
    resendConfirmation: 'Resend confirmation',
    learnMoreAt: 'Learn more at {{brandingWebsite}}',
    loginWithSso: 'Log in with single sign-on (SSO)',
    loginWithPersonal: 'Log in with personal account',
  },
  ChangeEmailForm: {
    email: 'Email',
    changeEmail: 'Change Email',
    back: 'Back',
    errorSameEmail: 'You have entered the same email you already have',
    required: 'Required',
  },
  ChangeEmailPage: {
    change: 'Change',
    next: 'Next',
    profile: 'Profile',
    myCompany: 'My Company',
    changeEmail: 'Change Email',
    verification: 'Verification',
    parameters: 'Parameters',
    emailField: 'Email',
    checkEmailForInstructions: 'Please check your email for further instructions',
    emailFieldDescription:
      'You can modify the email address of your account created on the {{brandingTitle}} Portal. Check your mailbox for the letter with the subject Confirm Changing Account Email.',
  },
  ConfirmOldEmailPage: {
    changeEmailAddress: 'Change Email Address',
    weReceivedRequest: 'We received a request to change your account email address.',
    toContinue: 'To continue, click the button below.',
    thisRequestIsValid:
      'This request is valid for 1 hour. If you did not make this request, please disregard this email.',
    proceedEmailAddressChange: 'Proceed Email Address Change',
    checkYourEmail: 'Please, check your new email for confirmation.',
  },
  ConfirmNewEmailPage: {
    confirmNewEmailAddress: 'Confirm New Email Address',
    toContinue: 'To continue, click the button below.',
    tankYouForConfirming: 'Thank you for confirming your new email.',
    thisRequestIsValid:
      'This request is valid for 1 hour. If you did not make this request, please disregard this email.',
    youInitiatedTheChange:
      'You initiated the change of your email address on the {{brandingTitle}} Portal. We just need to verify your email address before assigning the new email address to your account.',
  },
  BorderConfigsPage: {
    myCompany: 'My Company',
    environments: 'Environments',
    services: 'Services',
    borderConfigs: 'Border Configurations',
    borderConfigCreated: '"{{name}}" border configuration is created',
    borderConfigUpdated: '"{{name}}" border configuration is updated',
    name: 'Name',
    configurationId: 'Configuration ID',
    createdAt: 'Created At',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    newConfiguration: 'New Configuration',
    pageTitle: 'Border Configurator',
    pageDescription:
      '{{brandingTitle}} Border is a component of the {{brandingTitle}} Platform which allows you to take advantage of data residency services without developing any solutions with the {{brandingTitle}} SDK. This component integrates between your customer-facing frontend and system backend. It provides on-the-fly redaction and unredaction of regulated or sensitive data transmitted during execution of API requests.',
    exportToJSON: 'Export to JSON',
    importFromJSON: 'Import from JSON',
  },
  ImportBorderConfigPage: {
    myCompany: 'My Company',
    environments: 'Environments',
    services: 'Services',
    importBorderConfiguration: 'Import Border Configuration',
    borderConfigCreated: '"{{name}}" border configuration is created',
    borderConfigUpdated: '"{{name}}" border configuration is updated',
    pageTitle: 'Border Configurator',
    pageDescription:
      '{{brandingTitle}} Border is a component of the {{brandingTitle}} Platform which allows you to take advantage of data residency services without developing any solutions with the {{brandingTitle}} SDK. This component integrates between your customer-facing frontend and system backend. It provides on-the-fly redaction and unredaction of regulated or sensitive data transmitted during execution of API requests.',
  },
  EditBorderConfigPage: {
    myCompany: 'My Company',
    environments: 'Environments',
    services: 'Services',
    editBorderConfig: 'Edit Border Configuration',
    editBorderConfigName: 'Edit {{name}}',
    borderConfigCreated: '"{{name}}" border configuration is created',
    borderConfigUpdated: '"{{name}}" border configuration is updated',
    pageTitle: 'Border Configurator',
    pageDescription:
      '{{brandingTitle}} Border is a component of the {{brandingTitle}} Platform which allows you to take advantage of data residency services without developing any solutions with the {{brandingTitle}} SDK. This component integrates between your customer-facing frontend and system backend. It provides on-the-fly redaction and unredaction of regulated or sensitive data transmitted during execution of API requests.',
  },
  CreateBorderConfigPage: {
    myCompany: 'My Company',
    environments: 'Environments',
    services: 'Services',
    createBorderConfig: 'Create Border Configuration',
    createBorderConfigName: 'Create {{name}}',
    borderConfigCreated: '"{{name}}" border configuration is created',
    borderConfigUpdated: '"{{name}}" border configuration is updated',
    pageTitle: 'Border Configurator',
    pageDescription:
      '{{brandingTitle}} Border is a component of the {{brandingTitle}} Platform which allows you to take advantage of data residency services without developing any solutions with the {{brandingTitle}} SDK. This component integrates between your customer-facing frontend and system backend. It provides on-the-fly redaction and unredaction of regulated or sensitive data transmitted during execution of API requests.',
  },
  BorderConfigForm: {
    author: 'Author',
    target: 'Target',
    borderConfigId: 'Configuration ID',
    borderConfigName: 'Configuration Name',
    configurationVersion: 'Configuration Version',
    country: 'Country',
    parameters: 'Parameters',
    redactionRules: 'Redaction Rules',
    unredactionRules: 'Unredaction Rules',
    review: 'Review',
    verification: 'Verification',
    verificationWarning:
      'Please create an empty Border configuration and register its configuration identifier in the InCountry Data Residency for Salesforce package for the required form.',
    environmentId: 'Environment ID',
    configurationId: 'Configuration ID',
    borderTargetEndpoint: 'Border target endpoint',
    synchronizationEndpoint: 'Synchronization endpoint',
    pageTitle: 'Border Configurator',
    pageDescription:
      '{{brandingTitle}} Border is a component of the {{brandingTitle}} Platform which allows you to take advantage of data residency services without developing any solutions with the {{brandingTitle}} SDK. This component integrates between your customer-facing frontend and system backend. It provides on-the-fly redaction and unredaction of regulated or sensitive data transmitted during execution of API requests.',
    cancel: 'Cancel',
    close: 'Close',
    back: 'Back',
    submit: 'Submit',
    next: 'Next',
    edit: 'Edit',
    create: 'Create',
  },
  BorderConfigParameters: {
    required: 'Required',
    mustBeValidUrl: 'Must be valid url',
    mustBeValidHttpsUrl: 'Must be valid https url',
    mustBe254: 'Must be 254 characters or less',
    borderConfigName: 'Configuration Name',
    country: 'Country',
    borderConfigId: 'Configuration ID',
    author: 'Author',
    configurationVersion: 'Configuration Version',
    target: 'Target',
    notValidTargetUrl: 'Target URL must use https scheme',
    notValidPath:
      'Please use the URL without any path unless this is required. You can specify the path further when configuring redaction and unredaction rules.',
  },
  BorderRedactionRules: {
    addRule: 'Add Rule',
    documentation: 'Documentation',
    requiresDtkAlert:
      'You selected one of the strategies that requires entry of the deterministic tokenization key. You can get this key in the InCountry Data Residency for Salesforce package after enabling the corresponding flag in the package settings. Please copy and paste the generated value into the field below:',
    dtkInputLabel: 'Deterministic tokenization key',
  },
  BorderRedactionRule: {
    path: 'Path',
    required: 'Required',
    method: 'Method',
    deleteRule: 'Delete Rule',
    operationMode: 'Operation Mode',
    processing: 'Processing',
    processingAndStorage: 'Processing & Storage',
    resolveValidationErrors: 'Please, resolve all validation errors before collapsing this rule',
  },
  BorderRedactionRuleCollection: {
    collection: 'Collection',
    collectionName: 'Collection Name',
    required: 'Required',
    entityIdPath: 'Entity ID Path',
    entityErrorCorrectionFieldPath: 'Entity Error Correction Field Path',
    strategies: 'Strategies',
    searchable: 'Searchable',
    globalEntityId: 'Global Entity ID',
    entityIdPathHelper: 'Please ensure that the regular expression matches the path of your request.',
    errorCorrectionFieldHelper:
      'Enter the path to the record identifier that is returned within the response to this request.',
    collectionNameHelper:
      'Please give a meaningful and unique name to the collection that will allow Border to correctly differentiate between entities even when they have the same identifier',
  },
  RenderSearchable: {
    addSearchable: 'Add Searchable',
    searchable: 'Searchable',
  },
  RenderStrategies: {
    addStrategy: 'Add Strategy',
    errorCorrectionField: 'Error Correction Field',
    strategies: 'Strategies',
  },
  BorderRedactionRuleStrategy: {
    path: 'Path',
    strategy: 'Strategy',
    deleteStrategy: 'Delete Strategy',
    value: 'Value',
    required: 'Required',
    length: 'Length',
    searchableKey: 'Searchable Key',
    aggregated: 'Aggregated',
    searchable: 'Searchable',
    notSearchable: 'Not searchable',
    dtkLengthAlert:
      'If you use the InCountry Data Residency for the Salesforce package, please ensure that the tokenization functions and the length value coincide between Border and Salesforce packages',
    lengthAlert:
      'To preserve the consistency of outputted redacted values, please consider that the InCountry Data Residency for Salesforce package always uses the default length of 64 characters when using the similar redaction strategy. Changing this value may result in the inconsistency of redacted values between different components of the platform.',
  },
  BorderUnredactionRuleCollection: {
    collection: 'Collection',
    collectionName: 'Collection Name',
    required: 'Required',
    entityIdPath: 'Entity ID Path',
    entityErrorCorrectionFieldPath: 'Entity Error Correction Field Path',
    strategies: 'Strategies',
    deleteCollection: 'Delete Collection',
    globalEntityId: 'Global Entity ID',
  },
  BorderUnredactionRules: {
    addRule: 'Add Rule',
    documentation: 'Documentation',
  },
  BorderUnredactionRule: {
    addCollection: 'Add Collection',
    deleteRule: 'Delete Rule',
    path: 'Path',
    method: 'Method',
    required: 'Required',
    processing: 'Processing',
    processingAndStorage: 'Processing & Storage',
    resolveValidationErrors: 'Please, resolve all validation errors before collapsing this rule',
  },
  BorderUnredactionRuleStrategy: {
    path: 'Path',
    originalPath: 'Original Path',
    deleteStrategy: 'Delete Strategy',
    required: 'Required',
  },
  UserRoleChip: {
    owner: 'Owner',
    member: 'Member',
    auditor: 'Configuration Auditor',
    configurationAdministrator: 'Configuration Administrator',
    accountManager: 'Account Manager',
    guest: 'Guest',
    loading: 'Loading...',
  },
  FooterMenu: {
    incountryAddress: '{{brandingWebsite}}',
    contactSupport: 'Contact Support',
    phoneSupport: 'Call Support',
    documentation: 'Documentation',
    privacyPolicy: 'Privacy Policy',
    terms: 'Terms & Conditions',
  },
  SubscriptionPlan: {
    fetching: 'Fetching',
  },
  Page404: {
    title: 'Error 404 - Page not found',
    subTitle: 'Sorry, but the page you were looking for could not be found.',
    youCanReturn: 'You can return to our',
    gettingStartedPage: 'Getting Started Page',
    or: ', or',
    contactUs: 'contact us',
    ifYouCantFind: "if you can't find what you're looking for.",
  },
  Page500: {
    title: 'Internal Server Error',
    subTitle: 'Something went wrong',
    serverEncounteredError:
      'The server encountered an internal error or misconfiguration and was unable to complete your request.',
    contactUs: 'contact us',
    home: 'Home',
  },
  ShowCredentialsComponent: {
    copyToClipboard: 'Copy To Clipboard',
    successfullyCopiedToClipboard: 'Successfully copied to clipboard',
    downloadText: 'Download Text',
    copy: 'Copy',
    successfullyCopied: 'Successfully copied',
  },
  PaymentVaultsPage: {
    showCode: 'Show Code',
    delete: 'Delete',
    paymentVaults: 'Payment Vaults',
    newPaymentGateway: 'New Payment Vault',
    pgPageTitle: 'What is Payment Vault?',
    pgPageDescription:
      'Payment Vault is a technology solution that enables the secure handling of payment card data, storing it in the isolated data store, and providing to payment providers once the cardholder performs a payment or subscribes to a service.  It uses data tokenization to communicate payment card data between the cardholder and the target payment provider and secures it from potential threats during transmission. Payment Vault allows you to be PCI DSS compliant without implementing any additional functionality on your side.',
  },
  StepperComponent: {
    close: 'Close',
    cancel: 'Cancel',
    back: 'Back',
  },
  CreatePaymentVaultForm: {
    environmentId: 'Environment ID',
    newPaymentGateway: 'New Payment Vault',
    country: 'Country',
    required: 'Required',
    parameters: 'Parameters',
    paymentProvider: 'Payment Provider',
    environmentSetup: 'Environment Setup',
    review: 'Review',
    embedCode: 'Embed Code',
    create: 'Create',
    pgStep1Description:
      'Configuration of a Payment Vault is quite simple. Select the provider that will process the payment and the country where the payment card data will be saved. Once this is complete, please proceed to the next step.',
    previous: 'Previous',
    environment: 'Environment',
    SDK: 'SDK',
    name: 'Name',
    requestType: 'Request type',
    back: 'Back',
    applicationPaymentEndpoint: 'Application payment endpoint',
    pgStep2Title1: 'Setup of {{brandingTitle}} infrastructure',
    pgStep2Description1:
      'Once you have defined the country and the payment provider, you need to configure the environment for storing data and the SDK whose credentials will be used to authorize data requests. You can either select the existing environment and SDK or let {{brandingTitle}} Portal create them from scratch.',
    pgStep2Title2: 'Environment',
    pgStep2Description2:
      'The environment defines the operational context for storing regulated data on the {{brandingTitle}} platform. You can use environments to regulate access to stored data.',
    pgStep2Title3: 'SDK',
    pgStep2Description3:
      'The SDK is a set of credentials you can use to access regulated data stored within a specific environment. An SDK includes a Client ID and Secret that are further used to acquire an access token.',
    pgStep3Title1: 'Payment Vault setup',
    pgStep3Description1:
      'Once you have defined the environment and the SDK, give a name to the Payment Vault service. In addition, please register your application’s endpoint to process payment data.',
    pgStep3Title2: 'Name',
    pgStep3Description2:
      'Give some meaningful name to the Payment Vault service you set up so you can easily understand what it means and why it was created.',
    pgStep3Title3: 'Application payment endpoint',
    pgStep3Description3:
      'The application payment endpoint is a channel to communicate payment data between your application and payment providers. Payment Vault intercepts such data requests, swaps the payment card data with tokenized values, and communicates clear-text payment data only with the payment provider. This way your application does not store customer’s payment card data as it is pulled from the {{brandingTitle}} platform.',
    pgStep4Title1: 'Review configuration of Payment Vault',
    pgStep4Description1:
      'Before you save your Payment Vault’s configuration, please review it and ensure that everything is correct. First, ensure that you selected the right country and payment provider to work with. Check that payment card data will be saved into the correct environment on the {{brandingTitle}} platform. The last thing to check is the application payment endpoint where payment data is communicated. Once you have verified all of the configuration, click Save.',
    paymentDataEndpoint: 'Payment Data Endpoint',
    paymentGatewayName: 'Payment Vault Name',
    configurationReview: 'Configuration Review',
    enterTotpCode: 'Enter TOTP or Recovery Code',
    enterEmailCode: 'Enter the code from your email',
    useVerificationCode: 'Use Verification Code',
    verificationCode: 'Verification Code',
    confirmationNotice: 'Please check entered information one more time before continuing.',
    provider: 'Provider',
    endpoint: 'Endpoint',
    downloadText: 'Download Text',
    paymentGatewayCreated: 'Payment Vault is created',
    paymentGatewayCreatedSubtitle:
      'Your service with Payment Vault has been created successfully and now you can use it to handle your customers’ payment card data.',
    copyWarning:
      'Copy or download the automatically generated data now, as you will not be able to view this data again.',
    embedService: 'Embed the service code of the Payment Vault into your web application within its head section.',
    embedCodeCopied: 'Embed Code is copied',
    environmentIdIsCopied: 'Environment ID copied successfully',
    embedCodeTitle: 'Embed code of Payment Vault',
    embedCodeSubtitle:
      'The following is a sample script that you embed within your web application to handle payment card data. It includes the functions to handle payment card data and reflects the configuration you defined in previous steps.',
    pgDocumentation: 'Payment Elements documentation',
    providerIsCopied: 'Provider copied successfully',
    providerNameIsCopied: 'Provider Name copied successfully',
    countryIsCopied: 'Country name copied successfully',
    endpointIsCopied: 'Endpoint copied successfully',
    paymentGateway: 'payment vault',
    embedSnippet: 'Embed Snippet',
    paymentGatewayEmbedSnippet: 'Payment Vault Embed Snippet',
    showCode: 'Show Code',
    createNewEnvironment: 'New Environment',
    save: 'Save',
    paymentGatewayEmailRequest:
      'Payment Vault operates in the experimental mode with the limited support of payment providers and countries. Help us prioritize our roadmap with payment providers and countries you want to have',
    paymentGatewayDropEmail: 'by dropping an email to us.',
    paymentGatewayCannotFindProvider: 'Cannot find your payment provider?',
    paymentGatewayCannotFindCountry: 'Cannot find the necessary country?',
    letUsKnow: 'Let us know',
    iframeSource: 'IFrame Source',
    iframeSourceCopied: 'IFrame Source copied successfully',
    iframe: 'IFrame',
    close: 'Close',
    next: 'Next',
    cancel: 'Cancel',
  },
  PaymentVaultDeletePage: {
    deletePaymentGateway: 'Delete Payment Vault',
    areYouSureDeletePaymentGateway: 'Are you sure you want to delete payment vault?',
    thisWillDelete:
      'This will permanently delete the {{name}} payment vault. This action cannot be undone.\n\nDeleting a payment vault could break your service.',
    paymentVaults: 'Payment Vaults',
    delete: 'Delete',
    areYouSure: 'Are you sure you want to delete this payment vault?',
  },
  PaymentVaultEmbedPage: {
    embedService: 'Embed the service code of the Payment Vault into your web application within its head section.',
    embedCodeCopied: 'Embed Code is copied',
    embedCodeTitle: 'Embed code of Payment Vault',
    embedCodeSubtitle:
      'The following is a sample script that you embed within your web application to handle payment card data. It includes the functions to handle payment card data and reflects the configuration you defined in previous steps.',
    embedSnippet: 'Embed Snippet',
    paymentGatewayEmbedSnippet: 'Payment Vault Embed Snippet',
    paymentVaults: 'Payment Vaults',
    iframeSource: 'IFrame Source',
    iframeSourceCopied: 'IFrame Source copied successfully',
    embedCode: 'Embed Code',
    close: 'Close',
  },
  ShowBorderServiceCredentials: {
    environmentId: 'Environment ID',
    borderAddress: 'Proxy Address',
    borderServiceCreated: 'Border Service is created',
    environmentIdIsCopied: 'Environment ID copied successfully',
    copy: 'Copy',
    makeSureToCopy:
      'Please copy this information now.\nYou will no longer be able to see it again after closing this page! Do you really want to continue?',
  },
  BorderConfigReview: {
    reviewAndConfirm: 'Configuration review and confirmation',
  },
  EmailGatewaysPage: {
    name: 'Name',
    smtp: 'SMTP',
    environment: 'Environment',
    createdAt: 'Created',
    emailGateways: 'Email Gateways',
    newEmailGateway: 'New Email Gateway',
    details: 'Details',
    delete: 'Delete',
    edit: 'Edit',
    egPageTitle: 'What is Email Gateway?',
    egPageDescription:
      'Email Gateway is a technology solution that enables the secure swapping of value placeholders with the clear-text values of regulated data in emails. It is placed between the SDK application located outside the country of origin and the SMTP server located in the country of origin. Email Gateway captures outbound emails with regulated data placeholders, identifies them according to pre-defined patterns, and replaces these placeholders with clear-text data, such as names and email addresses. Once unredacted, emails are sent via a direct SMTP connection to the customer’s SMTP server that further delivers this email to the target recipient.',
  },
  EmailGatewayEmbedPage: {
    emailGateway: 'Email Gateway',
    emailGateways: 'Email Gateways',
    emailGatewayDetails: 'Email Gateway Details',
    emailGatewayDetailsDesc: 'Use this address for your service.',
    embedEmailService: 'Use service SMTP address to send you email with Email Gateway.',
    close: 'Close',
  },
  CreateEmailGatewayForm: {
    newEmailGateway: 'New Email Gateway',
    country: 'Country',
    emailGateway: 'Email Gateway',
    environmentSetup: 'Environment setup',
    name: 'Name',
    review: 'Review',
    results: 'Results',
    back: 'Back',
    create: 'Create',
    next: 'Next',
    cancel: 'Cancel',
    close: 'Close',
    required: 'Required',
    environment: 'Environment',
    environmentId: 'Environment ID',
    sdkId: 'Client ID',
    sdkSecret: 'Client Secret',
    createNewEnvironment: 'New Environment',
    emailGatewayName: 'Email Gateway Name',
    embedEmailService: 'Use this address for your Email Gateway service',
    smtpServer: 'SMTP Server',
    port: 'Port',
    username: 'Username',
    password: 'Password',
    emailGatewayLogin: 'Email Gateway Login',
    emailGatewayPassword: 'Email Gateway Password',
    configurationReview: 'Configuration Review',
    emailGatewayCreated: 'Email Gateway Created',
    emailGatewayCreatedSubtitle:
      'Your service with Email Gateway has been created successfully and now you can use it to process outbound emails with regulated data.',
    copyWarning:
      'Copy or download the automatically generated data now, as you will not be able to view this data again.',
    egStep1Title1: '',
    egStep1Description1:
      'Configuring the Email Gateway is quite simple. Simply enter the SMTP server address and the SMTP port for sending emails in addition to providing the necessary credentials to connect to it.',
    egStep1Title2: 'SMTP Server',
    egStep1Description2:
      'An SMTP (Simple Mail Transfer Protocol) server is an application that powers the protocol your email client uses when sending, receiving, and/or relaying outgoing messages. An SMTP server has an address that can be set by the mail  or application that you are using and is generally formatted as smtp.serveraddress.com.',
    egStep1Title3: 'Port',
    egStep1Description3:
      'This is a port that an SMTP Server uses for sending emails. By default, port 25 is used for SMTP relay, port 465 is used for implicit SSL/TLS communication, and ports 587 or 2525 for explicit SSL/TLS communication.',
    egStep1Title4: 'Username/password',
    egStep1Description4: 'The credentials to connect to and authorize the SMTP server to send emails.',
    egStep2Title1: 'Setup of {{brandingTitle}} infrastructure',
    egStep2Description1:
      'Once you have configured the SMTP server, you need to configure the environment for storing regulated data and the SDK whose credentials will be used to authorize data requests for email processing. You can either select an existing environment and SDK or let {{brandingTitle}} Portal create them from scratch.',
    egStep2Title2: 'Environment',
    egStep2Description2:
      'The environment defines the operational context for storing regulated data on the {{brandingTitle}} platform. You can use environments to regulate access to stored data.',
    egStep3Title: 'Review configuration of Email Gateway',
    egStep3Description:
      'Before you save your Email Gateway’s configuration, please review it and verify that everything is correct. Ensure that you have specified the correct configuration and credentials to connect to your SMTP server. Once this is done, enter the verification code and click Save.',
  },
  EmailGatewayDeletePage: {
    deleteEmailGateway: 'Delete Email Gateway',
    emailGateways: 'Email Gateways',
    delete: 'Delete',
    areYouSureDeleteEmailGateway: 'Are you sure you want to delete this email gateway?',
    thisWillDelete:
      'This will permanently delete the `{{name}}` email gateway. This action cannot be undone.\n\nRemoval of an email gateway can make your service invalid.',
  },
  UpdateEmailGatewayForm: {
    smtpServer: 'SMTP Server',
    port: 'Port',
    username: 'Username',
    password: 'Password',
    required: 'Required',
    review: 'Review',
    editEmailGateway: 'Edit Email Gateway',
    configurationReview: 'Configuration Review',
    egStep1Title1: '',
    egStep1Description1:
      'Configuring the Email Gateway is quite simple. Simply enter the SMTP server address and the SMTP port for sending emails in addition to providing the necessary credentials to connect to it.',
    egStep1Title2: 'SMTP Server',
    egStep1Description2:
      'An SMTP (Simple Mail Transfer Protocol) server is an application that powers the protocol your email client uses when sending, receiving, and/or relaying outgoing messages. An SMTP server has an address that can be set by the mail client or application that you are using and is generally formatted as smtp.serveraddress.com.',
    egStep1Title3: 'Port',
    egStep1Description3:
      'This is a port that an SMTP Server uses for sending emails. By default, port 25 is used for SMTP relay, port 465 is used for implicit SSL/TLS communication, and ports 587 or 2525 for explicit SSL/TLS communication.',
    egStep1Title4: 'Username/password',
    egStep1Description4: 'The credentials to connect to and authorize the SMTP server to send emails.',
    egStep3Title: 'Review configuration of Email Gateway',
    egStep3Description:
      'Before you update your Email Gateway’s configuration, please review it and verify that everything is correct. Ensure that you have specified the correct configuration and credentials to connect to your SMTP server. Once this is done, enter the verification code and click Update.',
    cancel: 'Cancel',
    back: 'Back',
    update: 'Update',
    next: 'Next',
  },
  SsoPage: {
    enterOrganizationId: 'Please enter your Organisation ID to log in with custom SSO',
    dataResidency: 'Data Residency-as-a-Service',
    supporting: 'supporting 90+ countries around the world',
    description:
      '{{longBrandingTitleWithAbbreviation}} provides a unique data residency-as-a-service platform that eases your compliance burden, letting you easily access new markets, regardless of data regulations, within tight time frames.',
    descriptionDCC:
      '{{longBrandingTitleDCC}} provides a unique data residency-as-a-service platform that eases your compliance burden, letting you easily access new markets, regardless of data regulations, within tight time frames.',
    learnMoreAt: 'Learn more at {{brandingWebsite}}',
    login: 'Log in',
    dontKnowOrgId: 'Don’t know your organisation ID?',
    organizationId: 'Organization ID',
    back: 'Back',
  },
  DashboardViewToggle: {
    mapView: 'Map view',
    tableView: 'Table view',
  },
  CopyToClipboardWrapper: {
    successfullyCopiedToClipboard: 'Successfully copied to clipboard',
    clickToCopy: 'Click to copy',
  },
  DeleteEntity: {
    delete: 'Delete',
    back: 'Back',
  },
  CustomFormValidation: {
    required: 'This field is required',
    minLength: 'This field should contain at least {{ minLength }} characters',
    maxLength: 'This field should not contain more than {{ maxLength }} characters',
    max: 'Maximum allowed value is {{ max }}',
    min: 'Minimum allowed value is {{ min }}',
    unsafeXss: 'This string is unsafe and cannot be used as value',
    nonBlank: "This field can't be blank or contain prohibited character",
    errorPassphrase:
      'Your passphrase should have at least one uppercase letter, at least one digit, and at least one special character',
  },
  SalesforceServiceCertificatePage: {
    environments: 'Environments',
    services: 'Services',
    service: 'Service',
    uploadCertificate: 'Upload Certificate',
    selectCertificate: 'Select Certificate',
    replaceCertificate: 'Replace Certificate',
    upload: 'Upload',
    verification: 'Verification',
    certificate: 'Certificate',
    errorMaximumFilesize: 'Your file have reached maximum allowed size limit with {{filesize}} of {{limit}}',
    fileIsNotSelected: 'File is not selected',
    fileIsNotProper: 'File is not a proper Certificate',
    successfullyReplaced: 'Successfully replaced',
    successfullyUploaded: 'Successfully uploaded',
    certificateDescription:
      'Select the certificate you generated in Salesforce for communication of regulated data with the {{brandingTitle}} platform',
    certificateConfirmDescription:
      'Confirm the upload the certificate you generated in Salesforce for communication of regulated data with the {{brandingTitle}} platform',
    next: 'Next',
  },
  DeleteBorderConfigPage: {
    myCompany: 'My Company',
    environments: 'Environments',
    delete: 'Delete',
    deleteBorderConfig: 'Delete Border Configuration',
    verification: 'Verification',
    parameters: 'Parameters',
    areYouSure: 'Do you really want to delete this border configuration',
    thisWillDelete:
      'This will permanently delete the `{{name}}` border configuration.\nThis action cannot be undone.\n',
    services: 'Services',
  },
  ViewBorderConfigPage: {
    environments: 'Environments',
    services: 'Services',
    borderConfig: 'Border Configuration',
    viewBorderConfig: 'View Border Configuration',
    environmentId: 'Environment ID',
    configurationId: 'Configuration ID',
    borderTargetEndpoint: 'Border target endpoint',
    synchronizationEndpoint: 'Synchronization endpoint',
    myCompany: 'My Company',
    close: 'Close',
  },
  DateDisplayComponent: {
    localTime: 'Local time',
  },
  SessionMaintainingDialog: {
    sessionIsAboutToExpire: 'Your session is about to expire',
    youWillBeLoggedOut: "You'll be logged out in {{seconds}} seconds.",
    staySignedIn: 'Do you want to stay signed in?',
    keepMeSignedIn: 'Yes, keep me signed in',
    logout: 'Logout',
  },
  SdkCredentialsInputDialog: {
    authentication: 'Resident Functions Authentication',
    inputClientSecret: 'Please, enter the Client Secret that you received upon the service creation.',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
  },
  FileDropzone: {
    dragAndDropTitle: 'Drag and Drop here',
    or: 'OR',
    browseFiles: 'Browse Files',
    maxFileSize: 'Max file size is {{max}}',
    cancel: 'Cancel',
    next: 'Next',
  },
  RestoreEnvironmentPage: {
    environments: 'Environments',
    restoreEnvironment: 'Restore Environment',
    restore: 'Restore',
    areYouSure: 'Are you sure?',
    description:
      'All the SDK credentials, services, encryption keys, and records stored within this environment will be unlocked.',
  },
};

const flatted = { values: translation };

export default flatted;
