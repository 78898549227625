export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

export type HttpMethodType = keyof typeof HttpMethod;

export const HttpMethods = Object.values(HttpMethod).filter(value => typeof value === 'string');
